import {
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from '@mui/material';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import RTL from 'components/RTL';
import useSettings from 'hooks/useSettings';
import { type FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { authRoutes, routes } from 'routes';
import { createCustomTheme } from 'theme';
import './i18n';
import AuthContext from 'contexts/JWTAuth';
import jwtDecode from 'jwt-decode';

const App: FC = ()  => {
  const navigate = useNavigate();
  const { refreshToken } = useContext(AuthContext);
  const [openPopup, setOpenPopup] = useState(false);
  useEffect(() => {
    const path = window.location.pathname.substring(1);
    if (authRoutes.map((x) => x.path).includes(path)) {
      return;
    }
    const tokenFromContext = refreshToken; 
    const tokenFromStorage = localStorage.getItem('refreshToken');

    const shouldResetTokens = () => {
      const isContextTokenInvalid = !tokenFromContext || !isValidToken(tokenFromContext);
      const isStorageTokenInvalid = !tokenFromStorage || !isValidToken(tokenFromStorage);
      return isContextTokenInvalid && isStorageTokenInvalid;
    };

    if (shouldResetTokens()) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setOpenPopup(true);
    }
  }, [refreshToken, navigate]);
  useEffect(() => {
   // loadIubendaScripts();
  }, []);
  const handlePopupClose = () => {
    setOpenPopup(false);
    navigate('/login');
  };
  const content = useRoutes(routes());
  const { settings } = useSettings();

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RTL>
          <CssBaseline />
          {content}
          <Dialog open={openPopup} onClose={handlePopupClose}>
            <DialogTitle>Sessione scaduta</DialogTitle>
            <DialogContent>
              <p>La tua sessione è scaduta. Devi rifare il login!</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handlePopupClose}>OK</Button>
            </DialogActions>
          </Dialog>
        </RTL>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
const isValidToken = (accessToken: string) => {
  if (!accessToken) return false;
  const decodedToken = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

export default App;
