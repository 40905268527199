import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, ButtonPropsColorOverrides } from '@mui/material';
import { ReactNode } from 'react';
import { OverridableStringUnion } from '@mui/types';
import ArchiveIcon from '@mui/icons-material/Archive';

export const CustomDialog = ({ open, onClose, onConfirm, title, content, icon, confirmColor, cancelColor, confirmText, isArchiveOptionAvailable, onConfirmArchive, zIndex }: {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void,
    title: string,
    content: string | ReactNode,
    icon: ReactNode,
    confirmColor: OverridableStringUnion<"primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides> | undefined
    cancelColor: OverridableStringUnion<"primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides> | undefined
    confirmText: string
    isArchiveOptionAvailable?: boolean;
    onConfirmArchive?: () => void;
    zIndex?: number;
}) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose} sx={{zIndex}}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color={cancelColor} variant='contained'>
                    {t('dialog.cancel')}
                </Button>
                <Button onClick={onConfirm} color={confirmColor} style={{ color: 'white' }} variant='contained' endIcon={icon}>
                    {confirmText}
                </Button>
                {isArchiveOptionAvailable &&
                    <Button onClick={onConfirmArchive} color={'success'} style={{ color: 'white' }} variant='contained' endIcon={<ArchiveIcon />}>
                        {t('housing.archive')}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};
