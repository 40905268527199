import React from 'react';
import { Container, Grid, useTheme, useMediaQuery } from '@mui/material';
import DefaultUserImage from '../assets/images/defaultUserPage.svg';
import Logo from '../assets/images/logo_onestaff.png';
const DefaultUserPage: React.FC = () => {

    const theme = useTheme();
    const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container maxWidth="lg" sx={{ height: '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
            {mobileBreakpoint && (
                <img src={Logo} alt="OneStaff Logo" style={{ width: '80px', height: 'auto', position: 'absolute', top: 20, left: '50%', transform: 'translateX(-50%)' }} />
            )}

            <Grid container sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={12} md={6} sx={{ order: mobileBreakpoint ? 2 : 1 }}>
                    <img src={DefaultUserImage} style={{ width: '100%', height: '90%' }} />
                </Grid>
            </Grid>
            {!mobileBreakpoint && (
                <img src={Logo} alt="OneStaff Logo" style={{ width: '120px', height: 'auto', position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)' }} />
            )}
        </Container>
    );
};

export default DefaultUserPage;
