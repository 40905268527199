import {
  AppBar,
  Box,
  IconButton,
  styled,
  type Theme,
  Toolbar,
  useMediaQuery,
  Autocomplete,
  Typography,
} from '@mui/material';
import { SettingsContext } from 'contexts/settingsContext';
import ThemeIcon from 'icons/ThemeIcon';
import { type FC, Fragment, useContext, useState, useEffect } from 'react';
import LanguagePopover from './popovers/LanguagePopover';
import ProfilePopover from './popovers/ProfilePopover';
import AppTextField from 'components/input-fields/AppTextField';
import { employeesApi, seasonApi } from 'api';
import { SeasonEntity } from 'api/generated';
import { useSeason } from 'contexts/SeasonContext';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import NotificationsPopover from './popovers/NotificationsPopover';
import EncryptionPopover from './popovers/EncryptionPopover';
import HiddenPopover from './popovers/HiddenPopover';
import { checkTokenRole } from 'utils/checkToken';


// ------------------------------------------------
interface DashboardHeaderProps {
  setShowSideBar: () => void;
  setShowMobileSideBar: () => void;
}

// ------------------------------------------------

// custom styled components
const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: 11,
  boxShadow: 'none',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  backdropFilter: 'blur(6px)',
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
}));

const StyledToolBar = styled(Toolbar)(() => ({
  '@media (min-width: 0px)': {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 'auto',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const ToggleIcon = styled(Box)<{ width?: number }>(({ theme, width }) => ({
  height: 3,
  margin: '5px',
  marginLeft: 0,
  width: width || 25,
  borderRadius: '10px',
  transition: 'width 0.3s',
  backgroundColor: theme.palette.primary.main,
}));

const DashboardHeader: FC<DashboardHeaderProps> = (props) => {
  const { setShowMobileSideBar } = props;

  const [isSeasonsLoaded, setIsSeasonsLoaded] = useState(false);
  const [defaultSeason, setDefaultSeason] = useState<undefined | { label: string; id: number }>();
  const { seasons, setSeasons, seasonId, setSeasonId } = useSeason();
  const { settings, saveSettings } = useContext(SettingsContext);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200));
  const { t } = useTranslation();
  const handleChangeTheme = (value: 'light' | 'dark') => {
    saveSettings({ ...settings, theme: value });
  };

  const checkToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decodedToken = jwtDecode<{ employeeId: number }>(accessToken);
      return decodedToken.employeeId
    }
    throw new Error("No valid token found!");
  }

  useEffect(() => {
    const fetchSeasonData = async () => {
      const employeeId = checkToken();
      const role = checkTokenRole();
      const selectedSeason = await employeesApi.getSeason(employeeId);
      if (role !== 'USER') {
        const { data } = role === 'DEPCHIEF' ? await seasonApi.findWhereIsBoss() : await seasonApi.findAll();
        setSeasons([...data]);
        setDefaultSeason(
          {
            label: selectedSeason.data ? selectedSeason.data.name : data[0].name,
            id: selectedSeason.data ? selectedSeason.data.id : data[0].id
          }
        );
        setSeasonId(selectedSeason.data ? selectedSeason.data.id : data[0].id);
        setIsSeasonsLoaded(true);
      };
    }

    fetchSeasonData();
  }, [seasonId]);

  return (
    <DashboardHeaderRoot position='sticky'>
      <StyledToolBar>
        {downMd && (
          <Box sx={{ cursor: 'pointer' }} onClick={setShowMobileSideBar}>
            <ToggleIcon />
            <ToggleIcon width={18} />
            <ToggleIcon width={9} />
          </Box>
        )}



        <Box flexGrow={1} ml={1} />
        {seasons.length > 0 ?
          <Autocomplete
            key={defaultSeason?.id}
            defaultValue={isSeasonsLoaded ? defaultSeason : undefined}
            id="seasons-selector"
            disableClearable
            options={seasons?.map((season: SeasonEntity) => ({
              label: season.name,
              id: season.id
            })) || []}
            renderInput={(params) =>
              <AppTextField
                {...params}
                placeholder={t('season.seasonSelector.select')}
                label={t('season.itemName')}
                size='small'
                sx={{
                  '.MuiInputBase-input': { fontSize: '0.7rem', fontWeight: '500' },
                  '#seasons-selector-label': { fontSize: '0.7rem', fontWeight: '500' },
                }}
              />
            }
            ListboxProps={{ style: { maxHeight: 150 } }}
            onChange={(event, value) => {
              setSeasonId(value.id);
              const employeeId = checkToken();
              employeesApi.setSeasonToDisplay({ employeeId, seasonId: value.id });
            }}
            style={{ width: '200px' }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Typography fontSize={12}>
                  {option.label}
                </Typography>
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.label === value?.label} // It serves to prevent alerts/errors mui in the console
          />
          : <></>
        }

        {settings.theme === 'light' ? (
          <StyledIconButton
            onClick={() => {
              handleChangeTheme('dark');
            }}
          >
            <ThemeIcon />
          </StyledIconButton>
        ) : (
          <StyledIconButton
            onClick={() => {
              handleChangeTheme('light');
            }}
          >
            <ThemeIcon />
          </StyledIconButton>
        )}

        <Fragment>
          <LanguagePopover />
        </Fragment>

        <NotificationsPopover />
        <EncryptionPopover />
        <HiddenPopover />
        <ProfilePopover />
      </StyledToolBar>
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;
