import { Box, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { Position } from "pages/shifts/shift-assignment";
import { FC, ReactNode } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { shiftApi } from "api";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useSeason } from "contexts/SeasonContext";
import { checkTokenRole } from "utils/checkToken";
import { addDays } from "date-fns";

interface DataPropos {
    resources: Position[];
    workPositionsIds: Array<{ id: number, name: string }>;
    workPositionMaxDimension: Record<number, number>;
    width: number;
    columns: number;
    setSelectedPosition: (position?: Position) => void;
    selectedPosition?: Position;
    fetchData: () => void;
    FIXED_HEIGHT: number;
    isWorkPositionVisible: Record<number, boolean>;
    MIN_WIDTH: number;
    hasDivider: boolean;
    isMultipleDays: boolean;
    departmentId: number;
    selectedDay: Date;

}


const ResourceColumn: FC<DataPropos> = (props) => {
    const { departmentId, resources, workPositionsIds, isWorkPositionVisible,hasDivider, isMultipleDays, MIN_WIDTH, workPositionMaxDimension, 
        width, columns, FIXED_HEIGHT, selectedPosition, setSelectedPosition, fetchData, selectedDay } = props;

    const theme = useTheme();

    const { t } = useTranslation();

    const isDepchief = checkTokenRole() === 'DEPCHIEF';


    const { seasonId } = useSeason();

    return (
        <Grid item xs={width} container columns={columns} minWidth={MIN_WIDTH} display={'flex'} alignItems={'center'}
            borderLeft={hasDivider ? 2 : 0}
            borderColor={theme.palette.primary[200]}
            paddingX={1}
        >

            {
                workPositionsIds.flatMap((id) => {
                    const items: ReactNode[] = [];
                    if (workPositionMaxDimension?.[id.id] > 0) {
                        items.push(<Grid item xs={columns} columns={columns} minHeight={FIXED_HEIGHT} padding={1}>
                            <FlexBox height={'80%'} > </FlexBox>
                        </Grid>)
                    }
                    if (isWorkPositionVisible[id.id]) {

                        const currentResources = resources?.filter(value => value.workPositionId === id.id) ?? [];
                        for (let i = 0; i < workPositionMaxDimension[id.id]; i++) {
                            // decide which color if employee is assigned or not
                            const bgColorBase = currentResources?.at(i)?.firstName ? theme.palette.success : theme.palette.error;

                            let bgColor = '';

                            if (currentResources?.at(i) && currentResources?.at(i) === selectedPosition)
                                bgColor = bgColorBase?.dark ?? '';
                            else
                                bgColor = bgColorBase?.main ?? '';

                            const leftPadding = (currentResources?.at(i)?.leftPadding ?? 0);

                            const rightPadding = (currentResources?.at(i)?.rightPadding ?? 0);


                            const resourceDimension = width - ((leftPadding > 0) ? leftPadding : 0) - ((rightPadding > 0) ? rightPadding : 0);

                            items.push(
                                <Grid item container xs={width} columns={width} minHeight={FIXED_HEIGHT}>
                                    {
                                        leftPadding > 0 && (
                                            <Grid xs={leftPadding} height={FIXED_HEIGHT}>

                                                <FlexBox
                                                    bgcolor={'lightgray'}
                                                    mt={0.5} borderRadius={2} mr={2} padding={1} height={'80%'} alignItems={'center'}
                                                    whiteSpace={'nowrap'} maxWidth={'100%'}
                                                />

                                            </Grid>
                                        )
                                    }

                                    <Tooltip
                                        title={(currentResources?.at(i)?.firstName || currentResources[i]?.start || currentResources[i]?.end) && (<>

                                            {
                                                currentResources?.at(i)?.firstName && currentResources?.at(i)?.lastName && (
                                                    <>
                                                        {(currentResources?.at(i)?.firstName ?? '') + currentResources?.at(i)?.lastName}
                                                        <br />
                                                    </>
                                                )


                                            }

                                            {
                                                currentResources[i]?.start && (
                                                    <>
                                                        {t('shift.startTime') + ': ' + currentResources[i].start?.toLocaleTimeString('it-IT', { hour: 'numeric', minute: 'numeric' }) ?? ''}
                                                        <br />
                                                    </>
                                                )

                                            }

                                            {
                                                currentResources[i]?.end && (
                                                    <>
                                                        {currentResources[i]?.end && (t('shift.endTime') + ': ' + currentResources[i].end?.toLocaleTimeString('it-IT', { hour: 'numeric', minute: 'numeric' }) ?? '')}
                                                    </>
                                                )
                                            }


                                        </>)}
                                    >


                                        <Grid item xs={resourceDimension} height={FIXED_HEIGHT} maxWidth={'100%'}
                                            zeroMinWidth>

                                            <FlexBox

                                                borderRight={theme.palette.action.disabled}
                                                bgcolor={currentResources[i] ? bgColor : 'lightgray'}
                                                mt={0.5} mr={currentResources?.[i]?.rightPadding > 0 ? 2 : 0} borderRadius={2} padding={1} height={'80%'} alignItems={'center'}
                                                whiteSpace={'nowrap'} maxWidth={'100%'}
                                                sx={{ '&:hover': currentResources[i] ? { bgcolor: bgColorBase?.dark } : {} }}
                                                onClick={() => {
                                                    if (currentResources[i] && !currentResources[i].firstName && currentResources?.at(i) !== selectedPosition)
                                                        setSelectedPosition(currentResources[i]);
                                                    else
                                                        setSelectedPosition(undefined);
                                                }}
                                            >

                                                {
                                                    currentResources?.at(i) && (currentResources[i].leftPadding < 0) && (
                                                        <KeyboardDoubleArrowLeftIcon></KeyboardDoubleArrowLeftIcon>
                                                    )
                                                }

                                                <Typography overflow={'hidden'} width={'auto'} textOverflow={'ellipsis'} marginRight={1}>
                                                    {currentResources?.at(i)?.firstName && currentResources?.at(i)?.lastName &&
                                                        (isMultipleDays ? (currentResources[i].firstName?.[0] ?? '') + currentResources[i].lastName?.[0]
                                                            : (currentResources[i].firstName ?? '') + currentResources[i].lastName)
                                                    }
                                                </Typography>

  <Box display={'flex'} ml={'auto'} alignItems={'center'}>

                                                    {
                                                        currentResources?.at(i)?.firstName && (!isDepchief || +addDays(currentResources?.at(i)?.date ?? selectedDay, 1) > +Date.now()) &&  (
                                                            <IconButton sx={{ height: FIXED_HEIGHT / 3, display: 'flex'}} onClick={async () => {
                                                            if (currentResources.at(i)?.assignmentId)
                                                                await shiftApi.removeEmployeeAssingment(currentResources?.at(i)?.assignmentId ?? 0, departmentId,
                                                                    seasonId, currentResources?.at(i)?.employeeId ?? 0
                                                                ).then(fetchData);
                                                            }}>
                                                                <ClearIcon></ClearIcon>
                                                            </IconButton>
                                                        )
                                                    }

                                                    {
                                                        currentResources?.at(i) && (currentResources[i].rightPadding < 0) && (

                                                            <KeyboardDoubleArrowRightIcon />



                                                        )
                                                    }
                                                </Box>




                                            </FlexBox>


                                        </Grid>
                                    </Tooltip>

                                    {
                                        rightPadding > 0 && (
                                            <Grid xs={rightPadding} height={FIXED_HEIGHT}>

                                                <FlexBox
                                                    bgcolor={'lightgray'}
                                                    mt={0.5} borderRadius={2} padding={1} height={'80%'} alignItems={'center'}
                                                    whiteSpace={'nowrap'} maxWidth={'100%'}
                                                />

                                            </Grid>
                                        )
                                    }
                                </Grid>
                            )
                        }

                    }


                    return items;


                })

            }
        </Grid>
    )
}

export default ResourceColumn;