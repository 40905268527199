import { Add, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, IconButton, styled, useTheme } from '@mui/material';
import FlexBetween from 'components/flexbox/FlexBetween';
import FlexBox from 'components/flexbox/FlexBox';
import { H3 } from 'components/Typography';
import { format } from 'date-fns';
import { enGB, de, it } from 'date-fns/locale';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

// component props interface
interface HeaderToolbarProps {
  onDatePrev: () => void;
  onDateNext: () => void;
  onAddEventForm?: () => void;
  onToday: () => void;
  date: Date;
}

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default,
  padding: '0.6rem 1.5rem',
  '&:hover': { backgroundColor: theme.palette.background.default },
  [theme.breakpoints.down(490)]: { width: '100%', mt: 1 },
}));

const HeaderToolbar: FC<HeaderToolbarProps> = ({
  onDateNext,
  onDatePrev,
  onAddEventForm,
  onToday,
  date,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = screen.width <= 768;

  const findSelectedLanguage = () => {
    switch (i18n.language) {
      case 'en':
        return enGB;
      case 'it':
        return it;
      case 'de':
        return de;
      default:
        return enGB;
    }
  }

  return (
    <FlexBetween
      flexWrap='wrap'
      sx={{ padding: '1rem 1.5rem', backgroundColor: 'primary.main' }}
    >
      <FlexBox
        alignItems='center'
        sx={{
          [theme.breakpoints.down(490)]: {
            width: '100%',
            justifyContent: 'space-between',
          },
        }}
      >
        <Box>
          <StyledIconButton onClick={onDatePrev}>
            <ChevronLeft sx={{ color: 'white' }} />
          </StyledIconButton>
          <StyledIconButton onClick={onDateNext}>
            <ChevronRight sx={{ color: 'white' }} />
          </StyledIconButton>
        </Box>
        <H3 ml='1rem' color='white'>
          {format(date, 'MMMM yyyy', { locale: findSelectedLanguage() })}
        </H3>
      </FlexBox>

      <StyledButton onClick={onToday}
        style={{
          marginBottom: isMobile ? '0.5rem' : '0',
          marginTop: isMobile ? '0.5rem' : '0'
        }}>
        {t('employees.calendar.buttons.today')}
      </StyledButton>

      {onAddEventForm &&
        <StyledButton startIcon={<Add />} onClick={onAddEventForm}>
          {t('employees.calendar.buttons.addEvent')}
        </StyledButton>
      }
    </FlexBetween>
  );
};

export default HeaderToolbar;
