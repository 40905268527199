import jwtDecode from "jwt-decode";


export const checkToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        const decodedToken = jwtDecode<{ employeeId: number }>(accessToken);
        return decodedToken.employeeId;
    }
    throw new Error("No valid token found!");
}

export const checkTokenRole = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        const decodedToken = jwtDecode<{ role: string }>(accessToken);
        return decodedToken.role;
    }
    throw new Error("No valid token found!");
}