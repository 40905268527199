import { Add, Download, DriveFolderUpload } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import FlexBetween from 'components/flexbox/FlexBetween';
import FlexBox from 'components/flexbox/FlexBox';
import SearchInput from 'components/input-fields/SearchInput';
import { H6 } from 'components/Typography';
import AddEmployeeModal from 'page-sections/data-table/dataTableV2/AddEmployeeModal';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { searchByName } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import emptyTableImage from 'assets/images/empty-table.png';
import { employeesApi, calendarEntriesApi } from '../../api';
import EmployeesDataTable from '../../page-sections/data-table/EmployeesDataTable';
import { format, isSameDay } from 'date-fns';
import { createExportCalendar, createReportForAllUsers, createWireTransfersReportForAllUsers } from 'utils/report';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddPaymentModal from 'page-sections/data-table/dataTableV2/AddPaymentModal';
import { useSeason } from 'contexts/SeasonContext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddEventModal, { AddWorkFormData } from 'page-sections/employees/calendar/AddEventModal';
import toast, { Toaster } from 'react-hot-toast';
import { CustomAnimatedDialog } from 'components/input-fields/CustomAnimatedDialog';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { CustomDialog } from 'components/CustomDialog';
import { EmployeeToSeasonEntity, EmployeeToSeasonEntityStatusTypeEnum } from 'api/generated';
import { useEmployeeContext } from 'contexts/EmployeeContext';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import WirePaymentsReportDatePicker from 'page-sections/employees/WirePaymentsReportDatePicker';
import CalendarReportDatesPicker from 'page-sections/employees/CalendarReportDatesPicker';
import BadgeIcon from '@mui/icons-material/Badge';
import UploadZipModal from 'page-sections/data-table/UploadZipModal';

interface DepartmentSelection {
  employeeId: string;
  departmentId: string;
}

// styled components
const ButtonWrapper = styled(FlexBox)(({ theme }) => ({
  [theme.breakpoints.down(500)]: {
    marginTop: 10,
    width: '100%',
    flexDirection: 'column-reverse',
    '& > .MuiBox-root': {
      width: '100%',
      margin: '10px 0',
      alignItems: 'center',
      flexDirection: 'column',
    },
    '& .MuiButton-root': { minWidth: '100%' },
  },
}));

interface ResourceProps {
  isSeasonal: boolean;
  isAll: boolean;
  showCandidate: boolean;
}

interface SwitchesProps {
  hideNotEmployeed: boolean;
  hideTerminated: boolean;
  handleHideTerminatedSwitch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleHideNonWorkingSwitch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SwitchesComponent: FC<SwitchesProps> = ({ handleHideTerminatedSwitch, handleHideNonWorkingSwitch, hideTerminated, hideNotEmployeed }) => {
  const { t } = useTranslation();
  return (
    <FlexBox alignItems="center">
      <FlexBox ml={2} alignItems="center">
        <Tooltip title={t('employees.filters.hideTerminated')} arrow>
          <Card>
            <FlexBox flexDirection="row" alignItems="center" p={1.3}>
              <RestoreFromTrashIcon />
              <Switch size="small" checked={hideTerminated} onChange={handleHideTerminatedSwitch} />
            </FlexBox>
          </Card>
        </Tooltip>
      </FlexBox>
      <FlexBox ml={2} alignItems="center">
        <Tooltip title={t('employees.filters.excludeNonValidContracts')} arrow>
          <Card>
            <FlexBox flexDirection="row" alignItems="center" p={1.3}>
              <BadgeIcon />
              <Switch size="small" checked={hideNotEmployeed} onChange={handleHideNonWorkingSwitch} />
            </FlexBox>
          </Card>
        </Tooltip>
      </FlexBox>
    </FlexBox>
  );
};

const EmployeesTable: FC<ResourceProps> = ({ isSeasonal, isAll, showCandidate }) => {
  const isMobile = useMediaQuery('(max-width:499px)');
  const { t } = useTranslation();
  const { seasonId } = useSeason();
  const employeeContext = useEmployeeContext();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([{}]);
  const [hasFilter, setHasFilter] = useState<string>('');
  const [clearFilter, setClearFilter] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [anchorDownloadReportMenu, setAnchorDownloadReportMenu] = useState<HTMLElement | null>(null);

  const [openModal, setOpenModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openAddEventModal, setOpenAddEventModal] = useState(false);
  const [openConflictsCustomDialog, setOpenConflictsCustomDialog] = useState(false);
  const [uploadZipModal, setUploadZipModal] = useState(false)
  // const [selectedDepartments, setSelectedDepartments] = useState<DepartmentSelection[]>([]);
  const [employeesAlertNames, setEmployeesAlertNames] = useState();
  const [hideTerminated, setHideTerminated] = useState(true);
  const [employeesData, setEmployeesData] = useState([{}]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteFromSeason, setDeleteFromSeason] = useState(false);
  const [excludeNotValidContracts, setExcludeNotValidContracts] = useState(true);
  const { encryptionKey } = useEncryption();
  const [wireReportDate, setWireReportDate] = useState(new Date());
  const [openWireReportModal, setOpenWireReportModal] = useState(false);
  const [openCalendarReportDatesPicker, setOpenCalendarReportDatesPicker] = useState(false);
  const isEncryptionMandatory = localStorage.getItem('isEncryptionMandatory');
  const isDisabled = isEncryptionMandatory === 'true' && encryptionKey === '';


  const handleAddEmployee = () => {
    employeeContext.setEmployeeId(-1);
    navigate('/dashboard/employees/new',
      {
        state: {
          isFromAllEmployees: !showCandidate
        }
      }
    );

  };

  const handleClickDownloadReportMenu = (event: any) => {
    event.stopPropagation();
    setAnchorDownloadReportMenu(document.getElementById('downloadReportButton'));
  };

  const handleCloseDownloadReportMenu = () => {
    setAnchorDownloadReportMenu(null);
  };

  const handleRowSelect = (rowArr: []) => {
    setSelectedRows(rowArr);
  };

  const handleConflictsCustomDialog = () => {
    setOpenConflictsCustomDialog(false);
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleClearFilter = () => {
    setClearFilter('...');
    setTimeout(() => {
      setClearFilter('');
    }, 50);
  };

  let fetchTableData: () => void;

  if (isAll) {
    fetchTableData = () => {
      employeesApi
        .findAll({
          params: {
            includeIsIncomplete: true,
            includeRoom: true,
            includeStatus: true,
            includeContract: true,
            includeNotes: true,
            includeTerminated: true,
            seasonId,
          },
        })
        .then(({ data: employees }) => {
          setEmployeesData(employees);
          if (Object.keys(tableData[0]).length === 0) {
            setLoading(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  } else if (!isSeasonal) {
    fetchTableData = () => {
      employeesApi
        .findUnlimitedEmployees({
          params: {
            includeIsIncomplete: true,
            includeRoom: true,
            includeStatus: true,
            includeContract: true,
            seasonId,
          },
        })
        .then(({ data: employees }) => {
          setEmployeesData(employees);
          if (Object.keys(tableData[0]).length === 0) {
            setLoading(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  } else {
    fetchTableData = () => {
      employeesApi
        .findNonUnlimitedEmployees({
          params: {
            includeIsIncomplete: true,
            includeRoom: true,
            includeStatus: true,
            includeContract: true,
            seasonId,
          },
        })
        .then(({ data: employees }) => {
          setEmployeesData(employees);
          if (Object.keys(tableData[0]).length === 0) {
            setLoading(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  }

  useEffect(() => {
    fetchTableData();
  }, [seasonId, isSeasonal, isAll]);

  useEffect(() => {
    setTableDataAndFilter();
  }, [employeesData, hideTerminated, excludeNotValidContracts, showCandidate]);

  const setTableDataAndFilter = () => {
    let filteredEmployees = employeesData;

    filteredEmployees = filteredEmployees.sort((a: any, b: any) => {
      const aFirstName = (a.firstName as string).toUpperCase();
      const aLastName = (a.lastName as string).toUpperCase();
      const bFirstName = (b.firstName as string).toUpperCase();
      const bLastName = (b.lastName as string).toUpperCase();

      // sort by first name
      if (aFirstName < bFirstName) return -1;
      if (aFirstName> bFirstName) return 1;
      // if it is the same sort by last name
      if (aLastName < bLastName) return -1;
      if (aLastName > bLastName) return 1;
      return 0;
    });

    filteredEmployees = filteredEmployees.filter((employee: any) => {
      if (employee.employeesToSeasons && employee.employeesToSeasons.length > 0) {
        const employeeToSeason: EmployeeToSeasonEntity = employee.employeesToSeasons[0];
        return (employeeToSeason.statusType === "CANDIDATE") === showCandidate;
      }
      return false;
    });

    if (hideTerminated) {
      filteredEmployees = filteredEmployees.filter((employee: any) => {
        // Check whether there are employeeToSeasons and whether there is at least one season with seasonId = seasonId and statusType='TERMINATED'.
        if (employee.employeesToSeasons && employee.employeesToSeasons.length > 0) {
          return !employee.employeesToSeasons.some((season: any) => {
            return season.seasonId === seasonId && season.statusType === "TERMINATED";
          });
        } else {
          // If there are no finished seasons, include this employee
          return true;
        }
      });
    }

    if (excludeNotValidContracts && !showCandidate) {
      const today = new Date();
      filteredEmployees = filteredEmployees.filter((employee: any) => {
        if (employee.employeesToSeasons && employee.employeesToSeasons.length > 0) {
          const employeeToSeason: EmployeeToSeasonEntity = employee.employeesToSeasons[0];
          // contract must exist
          if (employeeToSeason.contract) {
            const start = employeeToSeason.contract.startedOn ? new Date(employeeToSeason.contract.startedOn) : undefined;
            const end = employeeToSeason.contract.endedOn ? new Date(employeeToSeason.contract.endedOn) : undefined;
            // if there is not a start date the contract is not valid
            if (!start)
              return false;
            // start must be before now and end either not existing or after now
            if (+start <= +today && (!end || +end >= +today || isSameDay(end, today)))
              return true;
          } else {
            return false;
          }
        }
        return false;
      });
    }

    setTableData(filteredEmployees);
  };

  const ids = selectedRows.map((item: any) => item.original.id);
  const handleDelete = async (fromSeason: boolean) => {
    setDeleteFromSeason(fromSeason);
    const isOwnerIncluded = tableData?.some((entity: any) => entity.role === 'OWNER' && ids.includes(entity.id));
    if (isOwnerIncluded) {
      toast.error(t('employees.actions.deleteOwner'), { duration: 5000 });
      return;
    }
    handleOpenDeleteConfirmation();
  };

  // Handle confirm delete
  const handleConfirmDelete = async () => {
    const deleteActions = ids.map(async (id) => deleteFromSeason ? employeesApi.unassignSeason(id, seasonId) : employeesApi.remove(id));
    await Promise.all(deleteActions);
    fetchTableData();
    // Close confirmation dialog
    handleCloseDeleteConfirmation();
  };

  const handleArchiveEmployees = async () => {
    const statusType = EmployeeToSeasonEntityStatusTypeEnum.Terminated;
    const archiveActions = ids.map(async (employeeId) => employeesApi.assignStatus({
      data: {
        employeeId,
        statusType,
        seasonId,
        statusId: null,
      },
    }));
    await Promise.all(archiveActions);
    fetchTableData();
    handleCloseDeleteConfirmation();
  };

  // search input
  const [searchValue, setSearchValue] = useState('');
  const [filteredItem, setFilteredItem] = useState(tableData);
  useEffect(() => {
    if (tableData.length > 0 && Object.keys(tableData[0]).length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tableData]);

  useEffect(() => {
    const result = searchByName(tableData, searchValue);
    setFilteredItem(result);
  }, [searchValue, tableData]);

  // Download employees report
  const userIds = selectedRows.map((item: any) => item.original.id);
  const handleReport = async () => {
    employeesApi.getReportForAllUsers({
      data: {
        userIds,
        all: selectedRows.length === 0,
        seasonId,
      },
    })
      .then(async (response) => {
        const date = format(new Date(), 'dd/MM/yyyy');
        const fileName = `Report_${date}.xlsx`;
        await createReportForAllUsers(response.data, fileName, t, encryptionKey);
        setAnchorDownloadReportMenu(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Download employees wire transfer report
  const handleReportWireTransfers = async () => {
    employeesApi.getReportForAllUsers({
      data: {
        userIds,
        all: selectedRows.length === 0,
        seasonId,
      },
    })
      .then(async (response) => {
        const date = format(wireReportDate, 'dd/MM/yyyy');
        const fileName = `Wire_transfers_${date}.xlsx`;
        await createWireTransfersReportForAllUsers(response.data, fileName, t, encryptionKey, wireReportDate);
        setAnchorDownloadReportMenu(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function addEvent(calendarEntry: AddWorkFormData) {
    const { startedOn, endedOn, ...rest } = calendarEntry;
    const formattedCalendarEntry = {
      startedOn: startedOn.toISOString(),
      endedOn: endedOn.toISOString(),
      ...rest,
    };
    try {
      await calendarEntriesApi.createMany({
        data: {
          createCalendarEntryDto: formattedCalendarEntry,
          employeeSeasonPairs: selectedRows.map((employee: any) => {
            return {
              employeeId: employee.original.id,
              seasonId,
            };
          }),
        },
      });
      toast.success(t('alerts.addEventSuccessMessage'), { duration: 5000 });
    } catch (error: any) {
      if (error.response.status === 400) {
        const conflictingPairs = error.response.data.message;
        const conflictingPairsToArray = JSON.parse(conflictingPairs);
        const convertedIdsToNames = conflictingPairsToArray.map((pair: DepartmentSelection) => {
          const { employeeId } = pair;
          const employee = selectedRows.find((element: any) => element.original.id === employeeId) as any;
          return {
            employeeName: employee.original.firstName + ' ' + employee.original.lastName,
            employeeId,
          };
        });
        setEmployeesAlertNames(convertedIdsToNames);
        setOpenConflictsCustomDialog(true);
        toast.error(t('alerts.removeAllEmployeesCausingConflicts'), { duration: 5000 });
      }
    }
  }

  async function handleCalendarReport(startDate: Date, endDate: Date) {
    const formatedStartDate = format(startDate, 'dd/MM/yyyy');
    const formatedEndDate = format(endDate, 'dd/MM/yyyy');
    await createExportCalendar(
      selectedRows.length > 0 ? selectedRows.map((row: any) => {
        return { employeeId: row.original.id, name: row.original.firstName + ' ' + row.original.lastName };
      }) :
        tableData.map((employee: any) => {
          return { employeeId: employee.id, name: employee.firstName + ' ' + employee.lastName };
        }),
      startDate,
      endDate,
      `calendar_report_${formatedStartDate}_${formatedEndDate}_.xlsx`,
      t,
      seasonId,
    );
  }

  function SelectedRowsMessage() {
    return (
      <H6 mr={2} mt={isMobile ? 1.2 : 0}>
        {selectedRows.length < 2
          ? t('common.tables.select.numSelectedSingular', { num: selectedRows.length })
          : t('common.tables.select.numSelected', { num: selectedRows.length })
        }
      </H6>
    );
  }

  const handleHideTerminatedSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideTerminated(event.target.checked);
  };

  const handleHideNonWorkingSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExcludeNotValidContracts(event.target.checked);
  };

  const dialogContent = (length: number) => {
    if (deleteFromSeason) {
      if (length > 1)
        return t('dialog.confirmContent.multiEmployeesSeason', { num: length });
      else
        return t('dialog.confirmContent.singleEmployeeSeason');
    } else {
      if (length > 1)
        return t('dialog.confirmContent.multiEmployees', { num: length });
      else
        return t('dialog.confirmContent.singleEmployee');
    }
  };

  return (
    <Box pt={2} pb={4}>
      {loading ? (<>
        <SwitchesComponent
          hideTerminated={hideTerminated}
          hideNotEmployeed={excludeNotValidContracts}
          handleHideTerminatedSwitch={handleHideTerminatedSwitch}
          handleHideNonWorkingSwitch={handleHideNonWorkingSwitch}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh" // This will center the content vertically
          sx={{ gap: 5 }} // This adds space between the child elements
        >
          <img
            src={emptyTableImage}
            alt="Loading image"
            style={{ width: '250px', height: '250px' }} // Adjust as needed
          />
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: 'bold' }} // Adjust as needed
          >
            {t('employees.emptyTable.description')}
          </Typography>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            onClick={handleAddEmployee}
            sx={{ fontSize: 'larger' }} // Makes the button text larger
          >
            {showCandidate ? t('candidates.addCandidates') : t('employees.addEmployee')}
          </Button>
        </Box>
      </>) : (
        <>
          <CustomDialog
            open={deleteConfirmationOpen}
            onClose={handleCloseDeleteConfirmation}
            onConfirm={handleConfirmDelete}
            title={t(deleteFromSeason ? 'dialog.seasonTitle' : 'dialog.title', {
              item: t('dashboard.employees.title'),
            })}
            content={dialogContent(selectedRows.length)}
            icon={<DeleteForeverIcon />}
            confirmText={t('dialog.confirm')}
            confirmColor="error"
            cancelColor="primary"
            isArchiveOptionAvailable={!deleteFromSeason}
            onConfirmArchive={handleArchiveEmployees}
          />
          <WirePaymentsReportDatePicker
            open={openWireReportModal}
            date={wireReportDate}
            setDate={setWireReportDate}
            onClose={() => {
              setOpenWireReportModal(false);
              setWireReportDate(new Date());
            }}
            onSubmit={handleReportWireTransfers}
          />
          <CalendarReportDatesPicker
            open={openCalendarReportDatesPicker}
            onClose={() => {
              setOpenCalendarReportDatesPicker(false);
            }}
            onSubmit={handleCalendarReport}
          />
          <FlexBetween flexWrap="wrap">
            <FlexBox width={isMobile ? '100%' : 'auto'} alignItems="center">
              <SearchInput
                bordered={false}
                placeholder={showCandidate ? t('candidates.searchCandidates') : t('employees.searchEmployees')}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              {!showCandidate && (
                <SwitchesComponent
                  hideTerminated={hideTerminated}
                  hideNotEmployeed={excludeNotValidContracts}
                  handleHideTerminatedSwitch={handleHideTerminatedSwitch}
                  handleHideNonWorkingSwitch={handleHideNonWorkingSwitch}
                />
              )}
            </FlexBox>
            <ButtonWrapper alignItems="center">
              <FlexBox alignItems="center" mr={2}>
                {selectedRows.length > 0 && !isMobile && (
                  <SelectedRowsMessage />
                )}
                {selectedRows.length > 0 && isMobile && (
                  <SelectedRowsMessage />
                )}
                <Menu
                  anchorEl={anchorDownloadReportMenu}
                  open={Boolean(anchorDownloadReportMenu)}
                  onClose={handleCloseDownloadReportMenu}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          left: 'calc(50% - 5px)',
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setOpenWireReportModal(true);
                    }}
                    sx={{
                      width: '160px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingRight: '20px',
                    }}
                  >
                    {t('report.downloadWireTransfers')} <Download fontSize="small" />
                  </MenuItem>

                  <MenuItem
                    onClick={async () => {
                      setOpenCalendarReportDatesPicker(true);
                    }}
                    sx={{
                      width: '160px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingRight: '20px',
                    }}
                  >
                    {t('menu.apps.workShifts')} <Download fontSize="small" />
                  </MenuItem>
                </Menu>
              </FlexBox>

              {selectedRows.length === 0 && (
                <Button
                  id="uploadZipButton"
                  size="small"
                  color="success"
                  variant="contained"
                  onClick={() => { setUploadZipModal(true) }}
                  sx={{
                    color: 'common.white',
                    marginRight: isMobile ? 0 : 2,
                    marginTop: isMobile ? selectedRows.length > 0 ? 0 : 1.2 : 0,
                  }}
                  startIcon={<DriveFolderUpload fontSize="small" />}
                >
                  {t('table.uploadZip')}
                </Button>
              )}

              <Button
                id="downloadReportButton"
                size="small"
                color="success"
                variant="contained"
                onClick={handleReport}
                /**
                 * MarginTop: We have the problem that from mobile when we have 0 rows selected the button closes with the add employee button. 
                 * With this condition, when we are from mobile with zero rows selected, we add 1.2 margin top. In all other cases we set the top margin to 0.
                 */
                sx={{
                  color: 'common.white',
                  marginRight: isMobile ? 0 : 2,
                  marginTop: isMobile ? selectedRows.length > 0 ? 0 : 1.2 : 0,
                }}
                startIcon={<Download fontSize="small" />}
                endIcon={
                  <IconButton
                    onClick={(event) => {
                      handleClickDownloadReportMenu(event);
                    }}
                    style={{ padding: 0 }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                }
              >
                {selectedRows.length > 0 ? t('report.downloadSelected') : t('report.name')}
              </Button>

              {selectedRows.length > 0 && (
                <FlexBox alignItems="center" mr={2} gap={1.2}>
                  <Button
                    size="small"
                    color="success"
                    variant="contained"
                    onClick={() => {
                      setOpenAddEventModal(true);
                    }}
                  >
                    Clock
                  </Button>

                  <Tooltip title={isDisabled ? t('encryption.buttonDisabled') : ''} disableHoverListener={!isDisabled}>
                    <div>
                      <Button
                        size="small"
                        color="success"
                        variant="contained"
                        onClick={() => {
                          setOpenPaymentModal(true);
                        }}
                        disabled={isDisabled}
                      >
                        {t('payment.pay')}
                      </Button>
                    </div>

                  </Tooltip>

                  <Tooltip title={isMobile ? '' : t('common.tables.select.deleteSelected')}>
                    <Button
                      size="small"
                      color="error"
                      variant="contained"
                      onClick={() => {
                        handleDelete(false);
                      }}
                      sx={{
                        color: 'common.white',
                      }}
                    >
                      {isMobile ? t('common.tables.select.deleteSelected') : <DeleteForeverIcon fontSize="small" />}
                    </Button>
                  </Tooltip>

                  <Tooltip title={isMobile ? '' : t('common.tables.select.removeFromSeason')}>
                    <Button
                      size="small"
                      color="error"
                      variant="contained"
                      onClick={() => {
                        handleDelete(true);
                      }}
                      sx={{
                        color: 'common.white',
                      }}
                    >
                      {isMobile ? t('common.tables.select.removeFromSeason') :
                        <RemoveCircleOutlineIcon fontSize="small" />}
                    </Button>
                  </Tooltip>

                </FlexBox>
              )}

              {!!hasFilter && (
                <FlexBox alignItems="center" mr={2}>
                  <Button
                    size="small"
                    color="error"
                    variant="contained"
                    sx={{ color: 'common.white' }}
                    onClick={handleClearFilter}
                  >
                    {t('common.tables.filter.removeFilters')}
                  </Button>
                </FlexBox>
              )}

              <Tooltip title={selectedRows.length && !isMobile ? (showCandidate ? t('candidates.addCandidates') : t('employees.addEmployee')) : ''}>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={selectedRows.length && !isMobile ? '' : <Add />}
                  onClick={handleAddEmployee}
                >
                  {selectedRows.length && !isMobile ? <Add fontSize="small" /> : (showCandidate ? t('candidates.addCandidates') : t('employees.addEmployee'))}
                </Button>
              </Tooltip>
            </ButtonWrapper>
          </FlexBetween>

          <AddEmployeeModal
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
          />

          <AddPaymentModal
            open={openPaymentModal}
            data={userIds}
            onClose={() => {
              setOpenPaymentModal(false);
            }}
            setTableData={setTableData}
            tableData={tableData}
            employees={selectedRows}
          />

          <AddEventModal
            open={openAddEventModal}
            onClose={() => {
              setOpenAddEventModal(false);
            }}
            onSubmit={async (entry: AddWorkFormData) => {
              setOpenAddEventModal(false);
              await addEvent(entry);
            }}
            employees={selectedRows}
          />

          <UploadZipModal
            open={uploadZipModal}
            onClose={() => {
              setUploadZipModal(false);
            }}
          />

          <CustomAnimatedDialog
            open={openConflictsCustomDialog}
            onClose={handleConflictsCustomDialog}
            data={employeesAlertNames}
          />

          <Toaster />

          <Card sx={{ marginTop: 3 }}>
            <EmployeesDataTable
              data={filteredItem}
              clearFilter={clearFilter}
              handleRowSelect={handleRowSelect}
              onFilterChange={(filters) => {
                setHasFilter(filters.length);
              }}
            />
          </Card>
        </>
      )}
    </Box>
  );
};

export default EmployeesTable;
