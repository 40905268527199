import React, { Dispatch, FC, ReactChild, ReactFragment, ReactNode, ReactPortal, SetStateAction } from 'react';
import { Box, Card, Divider, Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import { H5, Small } from '../../components/Typography';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CreateAgreementDtoNoId } from 'page-sections/data-table/dataTableV2/AddAgreementModal';
import { IDFormData } from './IDDocuments';
import { EmployeeToSeasonEntity } from 'api/generated';
// import { SetLanguageDtoLanguageEnum } from 'api/generated';

export interface TabResourceProps<T> {
  data?: T;
  onSubmit: (data: T, setIsLoading?: Dispatch<SetStateAction<boolean>>, switchTab?: boolean) => Promise<any>;
  onSubmitAgreements?: (data: CreateAgreementDtoNoId) => Promise<any>;
  onNewDocument?: (data: IDFormData) => Promise<any>;
  id?: number;
  //  sendDataCompilationRequestCallback? : (data: T, language: SetLanguageDtoLanguageEnum) => Promise<any>;
  isProfileCompletition?: boolean;
  userMode: boolean;
  buttonFontSize?: string;
  employeeToSeasonData?: EmployeeToSeasonEntity;
  setNewDataCallback?: (data: any) => void;
  fetchEmployee?: (id: number) => void;
}

interface ResourceProps {
  title: string;
  children: ReactNode;
  setIsViewing: (value: React.SetStateAction<boolean>) => void;
  isViewing: boolean;
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isProfileCompletition?: boolean;
  titleComponent?: ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;
}

const TabBase: FC<ResourceProps> = ({
  title,
  children,
  setIsViewing,
  isViewing,
  handleSubmit,
  isProfileCompletition,
  titleComponent
}) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const changeToEditMode = () => {
    setIsViewing(false);
  };

  return (
    <Card sx={{ mt: 3, height: isProfileCompletition && !downMd ? '32rem' : '' }}>
      <Grid container alignItems='center' justifyContent='flex-end'>
        {!titleComponent && (
          <Grid item style={{ marginRight: 'auto' }}>
            <H5 padding={3}>{title}</H5>
          </Grid>
        )}
        {titleComponent && (
          <>{titleComponent}</>
        )}
        {isViewing && (
          <Grid item>
            <IconButton onClick={changeToEditMode}>
              <Edit sx={{ fontSize: 18 }} />
              <Small>{t('common.forms.button.edit')}</Small>
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Divider />
      <form id='tab-form' onSubmit={handleSubmit}>
        <Box margin={3}>{children}</Box>
      </form>
    </Card>
  );
};

export default TabBase;
