import { Button, FormControlLabel, Grid, IconButton, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { H5, H6 } from 'components/Typography';
import { KeyboardArrowDown } from '@mui/icons-material';
import React, { ChangeEvent, type FC, useEffect, useState } from 'react';
import AppSelectField from 'components/input-fields/MultipleChoiceField';
import { agreementApi, contractsApi, experienceLevelApi, rewardApi } from '../../api';
import {
  AgreementEntity,
  ContractEntityLegalContractTypeEnum,
  CreateContractDto,
  CreateContractDtoLegalContractTypeEnum,
  CreateRewardsDto,
  ExperienceLevelEntity,
  RewardEntity,
} from '../../api/generated';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import FileUploadInput from '../../components/input-fields/FileUploadInput';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';
import TabBase, { TabResourceProps } from './TabBase';
import CalendarInput from 'components/input-fields/CalendarInput';
import AddAgreememtModal, { AddAgreementFormData } from 'page-sections/data-table/dataTableV2/AddAgreementModal';
import AddIcon from '@mui/icons-material/Add';
import AgreementsDataTable from 'page-sections/data-table/AgreementsDataTable';
import FlexBox from 'components/flexbox/FlexBox';
import { useSeason } from 'contexts/SeasonContext';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import DeleteIcon from 'icons/DeleteIcon';
import { CustomDialog } from 'components/CustomDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddRewardModal from 'page-sections/data-table/dataTableV2/AddRewardModal';
import RewardsDataTable from 'page-sections/data-table/RewardsDataTable';
import UploadingOverlay from 'components/UploadOverlay';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export type ContractFormData = Omit<CreateContractDto, 'employeeId'> & {
  file?: File;
  filePath?: string | null;
  id: number | undefined;
};

interface ContractFormDataValidationSchema {
  file: File | undefined;
  filePath?: string | null;
  startedOn: string | null | undefined;
  endedOn: string | null | undefined;
  probationPeriod: string | null | undefined;
  levelName?: string;
  legalContractType: CreateContractDtoLegalContractTypeEnum;
}

const Contract: FC<TabResourceProps<ContractFormData>> = ({
  data,
  onSubmit,
  onSubmitAgreements,
  id,
  buttonFontSize
}) => {
  const isMobile = window.innerWidth <= 768;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [experienceLevels, setExperienceLevels] = useState<
    ExperienceLevelEntity[]
  >([]);
  const [isViewing, setIsViewing] = useState(!!data);
  const [dataForAgreements, setDataForAgreements] = useState<ContractFormData | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [agreements, setAgreements] = useState<AgreementEntity[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [legalContractType, setLegalContractType] = useState<CreateContractDtoLegalContractTypeEnum>(
    data?.legalContractType ? data.legalContractType : CreateContractDtoLegalContractTypeEnum.Limited);
  const [agreementData, setAgreementData] = useState<AddAgreementFormData | null>(null);
  const [agreementId, setAgreementId] = useState<number>();
  const [contractId, setContractId] = useState<number | null>(null);
  const [weeklyHours, setWeeklyHours] = useState<number | undefined>(data?.workPercentage ? Math.floor(40 / 100 * data.workPercentage) : 20);
  const { encryptionKey } = useEncryption();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const isEncryptionMandatory = localStorage.getItem('isEncryptionMandatory');
  const [rewards, setRewards] = useState<RewardEntity[]>([]);
  const [selectedRewards, setSelectedRewards] = useState<any[]>([]);
  const [rewardNames, setRewardNames] = useState<string[]>([]);
  const [documentFilePath, setDocumentFilePath] = useState<string | null>(data?.filePath ?? null);


  const { seasonId } = useSeason();
  const initialValues: ContractFormData = {
    file: data?.file ?? undefined,
    filePath: data?.filePath ?? undefined,
    startedOn: data?.startedOn ?? null,
    endedOn: data?.endedOn ?? null,
    levelName: data?.levelName ?? '',
    typeName: data?.typeName ?? '',
    legalContractType: data?.legalContractType ?? legalContractType,
    seasonId: data?.seasonId ?? seasonId,
    id: data?.id ?? undefined,
    probationPeriod: data?.probationPeriod ?? null,
    isAllIncluded: data?.isAllIncluded ?? false,
    isFoodIncluded: data?.isFoodIncluded ?? false,
    isPartTime: data?.isPartTime ?? false,
    workPercentage: data?.workPercentage ?? undefined
  };

  const validationSchema: ObjectSchema<ContractFormDataValidationSchema> = Yup.object().shape({
    file: Yup.mixed<File>(),
    filePath: Yup.string(),
    startedOn: Yup.string().nullable(),
    endedOn: Yup.string().nullable(),
    probationPeriod: Yup.string().nullable(),
    levelName: Yup.string(),
    legalContractType: Yup.mixed<CreateContractDtoLegalContractTypeEnum>()
      .oneOf(Object.values(CreateContractDtoLegalContractTypeEnum)).required(),
    isPartTime: Yup.boolean(),
    workPercentage: Yup.number().when('isPartTime', {
      is: true,
      then: (schema) => schema.required(t('contracts.validPercentage')).min(1, t('contracts.validPercentage')).max(99, t('contracts.validPercentage'))
    }),

  });
  const formikSubmit = (values: ContractFormData) => {
    onSubmit(values, setIsLoading, true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: formikSubmit,
  });
  const { values, handleBlur, handleChange, touched, errors, handleSubmit, resetForm, validateForm, setFieldValue } =
    formik;
  const [dateStarted, setdateStarted] = useState(values.startedOn && values.startedOn !== '' ? new Date(values.startedOn) : null);
  const [dateEnded, setdateEnded] = useState(values.endedOn && values.endedOn !== '' ? new Date(values.endedOn) : null);
  const [probationPeriod, setProbationPeriod] = useState(values.probationPeriod && values.probationPeriod !== '' ? new Date(values.probationPeriod) : null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      await formik.setFieldValue('file', files[0]);
    }
  };
  const initializeForm = () => {

    resetForm();
    setFieldValue('file', data?.file ?? undefined);
    setFieldValue('filePath', data?.filePath ?? undefined);
    setFieldValue('startedOn', data?.startedOn ?? null);
    setFieldValue('endedOn', data?.endedOn ?? null);
    setFieldValue('probationPeriod', data?.probationPeriod ?? null);
    setFieldValue('levelName', data?.levelName ?? '');
    setFieldValue('typeName', data?.typeName ?? '');
    setFieldValue('legalContractType', data?.legalContractType ?? ContractEntityLegalContractTypeEnum.Limited);
    setFieldValue('seasonId', data?.seasonId ?? seasonId);
    setFieldValue('workPercentage', data?.workPercentage ?? null);
    setContractId(data?.id ?? null);
    setdateStarted(data?.startedOn ? new Date(data?.startedOn) : null);
    setdateEnded(data?.endedOn ? new Date(data?.endedOn) : null);
    setProbationPeriod(data?.probationPeriod ? new Date(data?.probationPeriod) : null);
    validateForm();

  }

  const fetchAgreements = () => {
    agreementApi.findAll(id, seasonId).then(({ data }) => {
      setAgreements(data.sort((firstElement, secondElement) => new Date(firstElement.startedOn).valueOf() - new Date(secondElement.startedOn).valueOf()));
    });
  };

  const fetchRewards = () => {
    rewardApi.findAll().then((data) => {
      setRewards(data.data);
      setSelectedRewards([]);
      rewardApi.findAllNames().then(({ data }) => { setRewardNames(data.map(value => value.name)) });
    });
    
  }

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    formik.setFieldValue(field, (event.target.checked), true)
  }

  const handlePercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (!isNaN(value) || event.target.value === '') {
      formik.setFieldValue('workPercentage', event.target.value, true);
      const hours = Math.round(40 / 100 * value);
      setWeeklyHours(hours);
    }

  }

  const handleHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (!isNaN(value)) {
      setWeeklyHours(value);
      const percentage = value / 40 * 100;
      formik.setFieldValue('workPercentage', percentage.toFixed(2), true);
    }
    else {
      setWeeklyHours(undefined);
      formik.setFieldValue('workPercentage', 0, true);
      formik.setFieldValue('workPercentage', null, true);
    }
  }

  const handleRowSelect = (rowArr: []) => {
    setSelectedRows(rowArr);
  };

  const ids = selectedRows.map((item: any) => item.original.id);
  const handleDelete = async () => {
    const deleteActions = ids.map(async (id) => agreementApi.remove(id));
    await Promise.all(deleteActions);
    fetchAgreements();
  };
  const handleAddAgreement = (id: number, data?: AddAgreementFormData | null) => {
    setAgreementData(data ?? null);
    setAgreementId(id);
    setOpenModal(true);
  }
  const handleAddAgreementClick = async () => {
    if (data?.id && !contractId) {
      setContractId(data?.id);
    } else if (!contractId) {
      const submittedContractId = await onSubmit(values, setIsLoading, false);
      setContractId(submittedContractId);
    }
    setOpenModal(true);

  };

  const handleChangeButton = (
    event: React.MouseEvent<HTMLElement>,
    contractType: CreateContractDtoLegalContractTypeEnum,
  ) => {
    setLegalContractType(contractType);
    values.legalContractType = contractType;
  };

  useEffect(() => {
    setDataForAgreements(values)
  }, [values]);

  useEffect(() => {
    initializeForm();
  }, [data]);

  useEffect(() => {
    Promise.all([
      experienceLevelApi.findAll().then(({ data }) => {
        setExperienceLevels(data);
      }),
      agreementApi.findAll(id, seasonId).then(({ data }) => {
        setAgreements(data.sort((firstElement, secondElement) => new Date(firstElement.startedOn).valueOf() - new Date(secondElement.startedOn).valueOf()));
      }),
    ]);
    fetchRewards();
  }, []);

  useEffect(() => {
    const isNotSettings = window.location.hash !== '#settings';
    if (isNotSettings) {
      agreementApi.findAll(id, seasonId).then(({ data }) => {
        setAgreements(data.sort((firstElement, secondElement) => new Date(firstElement.startedOn).valueOf() - new Date(secondElement.startedOn).valueOf()));
      });
      initializeForm();
      resetForm();
    }
  }, [seasonId, window.location.hash]);

  const titleComponent = (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <H5 padding={3} style={{ fontSize: '15px' }} >{t('employees.contract.title')}</H5>
      </Grid>
      <Grid item>
        {data?.id && (
          <Button
            variant="contained"
            onClick={() => {
              setDeleteConfirmationOpen(true);
            }}
            style={{ backgroundColor: 'red', color: 'white', marginLeft: '1rem' }}
            startIcon={<DeleteIcon />}
          >
            {t('employees.contract.delete')}
          </Button>
        )}
      </Grid>
    </Grid>
  );


  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };


  // Handle confirm delete
  const handleConfirmDelete = async () => {
    if (data?.id) {
      await contractsApi.deleteContract(data.id);
      data = undefined;
      initializeForm();
      setDeleteConfirmationOpen(false);
    }
  };
  const [openRewardModal, setOpenRewardModal] = useState(false);

  const handleOpenRewardModal = () => { setOpenRewardModal(true) };
  const handleCloseRewardModal = () => { setOpenRewardModal(false) };
  const handleAddReward = async (values: any) => {
    if (!contractId)
      return;
    const rewardDTO: CreateRewardsDto = {
      amount: values.amount,
      earnedOn: values.date,
      name: values.rewardName,
      notes: values.notes,
      contractId
    }
    await rewardApi.createRewards(rewardDTO).then(() => { fetchRewards() });
    handleCloseRewardModal();
  };

  const handleDeleteRewards = async () => {
    const deleteActions = selectedRewards.map(async rewardId => {
      await rewardApi.deleteRewards(rewardId.original.id);
    });
    await Promise.all(deleteActions).then(() => {
      fetchRewards();
      
    });


  };


  const handleRemoveDocument = async () => {

    const updateContractDto: any = {
      filePath: null,
    };

    if (contractId)
      await contractsApi.update(contractId.toString(), updateContractDto);
    setFieldValue('file', undefined);
    setFieldValue('filePath', undefined);
    setDocumentFilePath(null);
  };

  return (
    <>
      <TabBase
        title={t('employees.contract.title')}
        setIsViewing={setIsViewing}
        isViewing={isViewing}
        handleSubmit={handleSubmit}
        titleComponent={titleComponent}
      >
        {isLoading &&
          <UploadingOverlay />
        }

        <ToggleButtonGroup
          orientation={isMobile ? 'vertical' : 'horizontal'}
          color="primary"
          value={legalContractType}
          exclusive
          onChange={handleChangeButton}
          aria-label="Platform"
          fullWidth
          sx={{ paddingBottom: '1.5rem' }}
          disabled={isViewing}
        >
          <ToggleButton value={CreateContractDtoLegalContractTypeEnum.Limited} disabled={legalContractType === CreateContractDtoLegalContractTypeEnum.Limited}>
            {t("employees.contract.types.limited")}
          </ToggleButton>
          <ToggleButton value={CreateContractDtoLegalContractTypeEnum.Unlimited} disabled={legalContractType === CreateContractDtoLegalContractTypeEnum.Unlimited}>
            {t("employees.contract.types.unlimited")}
          </ToggleButton>
          <ToggleButton value={CreateContractDtoLegalContractTypeEnum.Internship} disabled={legalContractType === CreateContractDtoLegalContractTypeEnum.Internship}>
            {t("employees.contract.types.internship")}
          </ToggleButton>
          <ToggleButton value={CreateContractDtoLegalContractTypeEnum.Call} disabled={legalContractType === CreateContractDtoLegalContractTypeEnum.Call}>
            {t("employees.contract.types.call")}
          </ToggleButton>
          <ToggleButton value={CreateContractDtoLegalContractTypeEnum.Apprenticeship} disabled={legalContractType === CreateContractDtoLegalContractTypeEnum.Apprenticeship}>
            {t("employees.contract.types.apprenticeship")}
          </ToggleButton>
        </ToggleButtonGroup>

        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <CalendarInput
              disabled={isViewing}
              format='dd.MM.yyyy'
              label={t('employees.contract.startingDate')}
              disableFuture={false}
              localeText={{
                fieldDayPlaceholder: () => t("DD"),
                fieldMonthPlaceholder: () => t("MM"),
                fieldYearPlaceholder: () => t("YYYY"),
              }}
              value={dateStarted}
              onChange={(newValue) => {
                if (newValue && !isNaN(new Date(newValue).getTime())) {
                  const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                  formik.setFieldValue('startedOn', utcDate?.toISOString(), true);
                  setdateStarted(newValue);
                }
              }}
              slotProps={{
                textField: {
                  helperText: t("DD") + "." + t("MM") + "." + t("YYYY")
                },
              }}
            />
          </Grid>
          {(legalContractType !== CreateContractDtoLegalContractTypeEnum.Unlimited || !isMobile) && (
            <Grid item sm={6} xs={12} >
              {legalContractType !== CreateContractDtoLegalContractTypeEnum.Unlimited && (

                <CalendarInput
                  disabled={isViewing}
                  format='dd.MM.yyyy'
                  label={t('employees.contract.expiryDate')}
                  disableFuture={false}
                  localeText={{
                    fieldDayPlaceholder: () => t("DD"),
                    fieldMonthPlaceholder: () => t("MM"),
                    fieldYearPlaceholder: () => t("YYYY"),
                  }}
                  value={dateEnded}
                  onChange={(newValue) => {
                    if (newValue && !isNaN(new Date(newValue).getTime())) {
                      const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                      formik.setFieldValue('endedOn', utcDate?.toISOString(), true);
                      setdateEnded(newValue);
                    }
                  }}
                  slotProps={{
                    textField: {
                      helperText: t("DD") + "." + t("MM") + "." + t("YYYY")
                    },
                  }}
                />

              )}
            </Grid>
          )}
          <Grid item sm={6} xs={12}>
            <CalendarInput
              disabled={isViewing}
              format='dd.MM.yyyy'
              label={t('employees.contract.probationPeriod')}
              disableFuture={false}
              localeText={{
                fieldDayPlaceholder: () => t("DD"),
                fieldMonthPlaceholder: () => t("MM"),
                fieldYearPlaceholder: () => t("YYYY"),
              }}
              value={probationPeriod}
              onChange={(newValue) => {
                if (newValue && !isNaN(new Date(newValue).getTime())) {
                  const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                  formik.setFieldValue('probationPeriod', utcDate?.toISOString(), true);
                  setProbationPeriod(newValue);
                }
              }}
              slotProps={{
                textField: {
                  helperText: t("DD") + "." + t("MM") + "." + t("YYYY")
                },
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <AppSelectField
              disabled={isViewing}
              InputLabelProps={{ shrink: true }}
              select
              fullWidth
              name='levelName'
              variant='outlined'
              label={t('employees.contract.experienceLevel.label')}
              placeholder=''
              SelectProps={{
                native: true,
                IconComponent: KeyboardArrowDown,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.levelName}
              helperText={touched.levelName && (errors.levelName as string)}
              error={Boolean(touched.levelName && (errors.levelName as string))}
            >
              <option value={''}>
                {t('employees.contract.experienceLevel.default')}
              </option>

              {experienceLevels.map((experienceLevel) => (
                <option key={experienceLevel.name} value={experienceLevel.name}>
                  {experienceLevel.name}
                </option>
              ))}
            </AppSelectField>
          </Grid>

          <Grid item xs={12} container>
            <Grid item sm={6} xs={12}>
              <FormControlLabel
                sx={{ minWidth: '0px' }}
                label={<Typography noWrap textOverflow={'ellipsis'} overflow={'hidden'}>{t('employees.contract.allIncluded')}</Typography>}
                labelPlacement='end'
                control=
                {<Switch
                  disabled={isViewing}
                  checked={values.isAllIncluded}
                  onChange={(e) => { handleCheckedChange(e, 'isAllIncluded') }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControlLabel
                sx={{ minWidth: '0px' }}
                label={<Typography noWrap textOverflow={'ellipsis'} overflow={'hidden'}>{t('employees.contract.foodIncluded')}</Typography>}
                labelPlacement='end'
                control=
                {<Switch
                  disabled={isViewing}
                  checked={values.isFoodIncluded}
                  onChange={(e) => { handleCheckedChange(e, 'isFoodIncluded') }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
              />
            </Grid>

          </Grid>

          <Grid item sm={6} xs={6} alignSelf={'end'}>
            <FormControlLabel
              sx={{ minWidth: '0px' }}
              label={<Typography noWrap textOverflow={'ellipsis'} overflow={'hidden'}>Part time</Typography>}
              labelPlacement='end'
              control=
              {<Switch
                disabled={isViewing}
                checked={values.isPartTime}
                onChange={(e) => {
                  handleCheckedChange(e, 'isPartTime');
                  if (e.target.value) {
                    setFieldValue('workPercentage', 50);
                    setWeeklyHours(20);
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />}
            />
          </Grid>


          {values.isPartTime && (
            <>
              <Grid item sm={3} xs={3}>
                <TextField
                  variant='standard'
                  fullWidth
                  id="standard-number"
                  label={t('contracts.workPercentage')}
                  value={values.workPercentage}
                  //  required={values.isPartTime}
                  onChange={handlePercentageChange}
                  //  type='number'
                  disabled={isViewing || !values.isPartTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={
                    {
                      endAdornment: <>%</>
                    }
                  }
                  helperText={formik.errors.workPercentage}
                  error={Boolean(formik.errors.workPercentage)}
                />
              </Grid>
              <Grid item sm={3} xs={3}>
                <TextField
                  variant='standard'
                  fullWidth
                  label={"ore"}
                  value={weeklyHours}
                  onChange={handleHourChange}
                  type='number'
                  disabled={isViewing || !values.isPartTime}

                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <H6 mb={1}>{t('employees.contract.contractFile')}</H6>
            {documentFilePath ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <a target='_blank' rel='noopener noreferrer' href={documentFilePath}>
                  {t('employees.contract.contractFile')}
                </a>
                <IconButton
                  disabled={isViewing}
                  style={{ marginLeft: '10px' }}
                  onClick={handleRemoveDocument}>
                  <CancelOutlinedIcon />
                </IconButton>
              </div>
            ) : (
              <FileUploadInput disabled={isViewing} handleOnChange={handleFileChange} maxDimension={100} />
            )}
          </Grid>
        </Grid>
      </TabBase>


      <TabBase
        title={t('employees.agreements.title')}
        setIsViewing={setIsViewing}
        isViewing={false}
        handleSubmit={handleSubmit}
      >
        <AddAgreememtModal
          edit={!!agreementData}
          data={agreementData}
          open={openModal}
          id={agreementId}
          agreements={agreements}
          contractData={dataForAgreements}
          contractId={contractId}
          onClose={() => {
            setOpenModal(false);
            setAgreementId(undefined);
            setAgreementData(null);
            fetchAgreements();
          }}
          // onSubmitAgreements callback is always set in Contract.tsx - by definition. Is optional only because we use the same TabBase of other tab
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onSubmit={onSubmitAgreements!}
        />
        {selectedRows.length > 0 && agreements.length > 0 && (
          <FlexBox alignItems='center' justifyContent={'flex-end'}>
            <H6 mr={1}>
              {t('common.tables.select.numSelected', {
                num: selectedRows.length,
              })}
            </H6>

            <Button
              size='small'
              color='error'
              variant='contained'
              onClick={handleDelete}
              sx={{ color: 'common.white' }}
            >
              {t('common.tables.select.deleteSelected')}
            </Button>
          </FlexBox>
        )}

        {agreements.length > 0 && (
          <AgreementsDataTable
            editAgreementCallback={handleAddAgreement}
            data={agreements}
            handleRowSelect={handleRowSelect}
            encryptionKey={encryptionKey}
          />
        )}

        <Grid container justifyContent="center" paddingTop={1}>
          <Button
            disabled={!(
              (dateStarted !== null && (dateEnded !== null || legalContractType === CreateContractDtoLegalContractTypeEnum.Unlimited)) &&
              !(isEncryptionMandatory === 'true' && encryptionKey === '')
            )}
            variant="contained"
            color="primary"
            type='button'
            startIcon={<AddIcon />}
            onClick={handleAddAgreementClick}
            style={{ fontSize: buttonFontSize }}
          >
            {t('common.tables.button.addItem', {
              item: t('employees.agreements.title'),
            })}
          </Button>
        </Grid>

      </TabBase >
      <CustomDialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        onConfirm={handleConfirmDelete}
        title={t('dialog.title', {
          item: t("contracts.itemName"),
        })}
        content={t('dialog.confirmContent.removeContract')}
        icon={<DeleteForeverIcon />}
        confirmText={t('dialog.confirm')}
        confirmColor='error'
        cancelColor='primary'
      />

      <TabBase
        title={t('employees.rewards.title')}
        setIsViewing={setIsViewing}
        isViewing={false}
        handleSubmit={handleSubmit}
      >
        {selectedRewards.length > 0 && (
          <FlexBox alignItems='center' justifyContent={'flex-end'}>
            <H6 mr={1}>
              {t('common.tables.select.numSelected', {
                num: selectedRewards.length,
              })}
            </H6>
            <Button
              size='small'
              color='error'
              variant='contained'
              onClick={handleDeleteRewards}
              sx={{ color: 'common.white' }}
            >
              {t('common.tables.select.deleteSelected')}
            </Button>
          </FlexBox>
        )}

        {rewards.length > 0 && (
          <RewardsDataTable
            data={rewards}
            handleRowSelect={(selectedIds) => { setSelectedRewards(selectedIds) }}
          />
        )}

        <Grid container justifyContent="center" paddingTop={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenRewardModal}
            style={{ fontSize: buttonFontSize }}
          >
            {t('common.tables.button.addItem', { item: t('employees.rewards.title') })}
          </Button>
        </Grid>
      </TabBase>

      <AddRewardModal open={openRewardModal} onClose={handleCloseRewardModal} onSubmit={handleAddReward} fetchRewards={fetchRewards} usedNames={rewardNames} />
    </>

  );
};

export default Contract;