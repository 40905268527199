import { Button, Grid, InputAdornment, styled } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import { H2 } from 'components/Typography';
import { useFormik } from 'formik';
import { useState, type FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import CalendarInput from 'components/input-fields/CalendarInput';
import { employeePaymentsApi } from '../../../api';
import { checkToken } from 'utils/checkToken';
import AppTextField from 'components/input-fields/AppTextField';
import { toast } from 'react-hot-toast';
import { useSeason } from 'contexts/SeasonContext';
import { EmployeeEntity } from 'api/generated';
import FileUploadInput from 'components/input-fields/FileUploadInput';
import UploadingOverlay from 'components/UploadOverlay';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import { encryptData } from 'utils/encryptionDecryptionAgreements';

// component props interface
interface ModalProps {
    open: boolean;
    onClose: () => void;
    employee: EmployeeEntity;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 450,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddSinglePaymentModal: FC<ModalProps> = ({
    open,
    onClose,
    employee,
}) => {
    const { t } = useTranslation();
    const [date, setDate] = useState(new Date());
    const authoriser = checkToken();
    const [employeesWithPayment, setEmployeesWithPayment] = useState<Array<{ id: number; amount: number, amount_encrypted: string, hasFile: boolean }>>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const { seasonId } = useSeason();
    const [file, setFile] = useState<File>();
    const { encryptionKey } = useEncryption();

    const initialValues = {
        date,
        amount: null
    };

    const fieldValidationSchema = Yup.object().shape({
        date: Yup.date().required(t('date.error')),
        amount: Yup.number().required(t('payment.invalidAmmount'))
    });

    const handleClose = () => {
        resetForm();
        onClose();
        setEmployeesWithPayment([]);
        setDate(new Date());
    }

    const { handleSubmit, resetForm, setFieldValue, touched, errors } = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        onSubmit: () => {
            const formData = new FormData();
            if (file) {
                formData.append('files', file);  // Append files
            }

            // Assuming 'employeesWithPayment' is an array or object
            formData.append('employeesWithPayment', JSON.stringify(employeesWithPayment));
            formData.append('authoriserId', authoriser.toString());  // Assuming 'authoriser' is a simple string or number
            formData.append('paymentDate', date.toISOString());
            formData.append('seasonId', seasonId.toString());  // Assuming 'seasonId' is a simple string or number

            setIsLoading(true);

            employeePaymentsApi
                .create({
                    data: formData
                })
                .then(() => {
                    handleClose();
                    toast.success(t('alerts.success'))
                    setFile(undefined);
                }).catch((e) => {
                    console.log(e);
                    handleClose();
                    toast.error(t('alerts.error'));
                    setFile(undefined);
                }).finally(() => {
                    setIsLoading(false);
                })
        },
    });

    return (
        <StyledAppModal open={open} handleClose={() => {
            handleClose();
        }}>
            {isLoading &&
                <UploadingOverlay />
            }
            <H2 mb={2}>
                {t('payment.collectionName')}
            </H2>

            <form onSubmit={handleSubmit}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            value={date}
                            onChange={(newValue) => {
                                setFieldValue('date', newValue);
                                if (newValue) {
                                    setDate(newValue);
                                }
                            }}
                            slotProps={{
                                textField: {
                                    helperText: errors.date && t('date.error'),
                                    error: Boolean(touched.date && errors.date)
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <FlexBox flexDirection={'column'} marginTop={'2rem'} gap={2}>
                    <FlexBox key={employee.id} justifyContent={'space-between'} alignItems={'center'}>
                        <span>{employee.firstName} {employee.lastName}</span>
                        <AppTextField
                            sx={{ width: '30%' }}
                            type='number'
                            size='small'
                            inputProps={{
                                min: 0,
                                step: 0.01,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                            }}
                            onChange={(e) => {
                                const newValue = parseFloat(e.target.value);
                                setFieldValue('amount', newValue);
                                if (!isNaN(newValue)) {
                                    setEmployeesWithPayment([
                                        {
                                            id: employee.id,
                                            amount: encryptionKey ? 0 : newValue,
                                            amount_encrypted: encryptionKey ? encryptData(newValue, encryptionKey) : '',
                                            hasFile: file !== undefined
                                        },
                                    ]);
                                }
                            }}
                            helperText={touched.amount && errors.amount}
                            error={Boolean(touched.amount && errors.amount)}
                        />
                    </FlexBox>
                    <FlexBox>
                        <FileUploadInput
                            maxDimension={30}
                            handleOnChange={(e) => {
                                if (e.target.files)
                                    setFile(e.target.files[0]);
                            }}
                        />
                    </FlexBox>
                </FlexBox>

                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {
                        handleClose();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth size='small' type='submit' variant='contained'>
                        {t('common.forms.button.save')}
                    </Button>
                </FlexBox>
            </form>
        </StyledAppModal>
    );
};

export default AddSinglePaymentModal;