import FullCalendar from "@fullcalendar/react";
import { Autocomplete, Box, Card, Grid, Tooltip, styled } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { useTranslation } from "react-i18next";
import itLocale from '@fullcalendar/core/locales/it';
import deLocale from '@fullcalendar/core/locales/de';
import enLocale from '@fullcalendar/core/locales/en-gb';
import HeaderToolbar from "page-sections/calendar/HeaderToolbar";
import { EventClickArg, EventContentArg, EventInput } from "@fullcalendar/core";
import { calendarEntriesShiftTemplateApi, departmentsApi } from "api";
import { CalendarEntryShiftTemplateEntity, ShiftTemplateEntity } from "api/generated";
import FlexBox from "components/flexbox/FlexBox";
import ViewEventModalShift from "./ViewEventModal";
import { EventImpl } from "@fullcalendar/core/internal";
import AddEventModalShift from "./AddEventModal";
import { Department } from "pages/dashboards/dashboard-main-page";
import AppTextField from "components/input-fields/AppTextField";
import { t } from "i18next";
import { checkTokenRole } from "utils/checkToken";
import { useSeason } from "contexts/SeasonContext";


interface ExtendedCalendarEntryShiftTemplateEntity extends CalendarEntryShiftTemplateEntity {
    shiftTemplate: ShiftTemplateEntity;
    templateId: number;
}

interface EventInputDepartmentId extends EventInput {
    departmentId?: number;
}


// styled component
const CalendarWrapper = styled(Box)(({ theme }) => ({
    padding: '1.5rem',
    '& .fc-theme-standard td, & .fc-theme-standard th': {
        border: 'none',
    },
    '& .fc-theme-standard, & .fc-scrollgrid': {
        border: 'none !important',
    },
    '& .fc .fc-scroller-harness-liquid': {
        marginTop: '1rem',
    },
    '& .fc th': {
        color: theme.palette.primary.main,
    },
    '& .fc-daygrid-day-top': {
        justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit',
    },
    '& .fc .fc-daygrid-day.fc-day-today': {
        borderRadius: '8px',
        border: `5px solid ${theme.palette.primary.main}`,
        backgroundColor: 'transparent',
    },
    '& .fc-daygrid-day-number': {
        fontWeight: 600,
    },
    '& .fc-h-event': {
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        textAlign: 'left',
        borderRadius: '4px',
        padding: '0 8px',
        height: '30px',
        lineHeight: '20px',
        fontSize: '12px',
    },
    '& .fc-daygrid-day-bottom a': {
        backgroundColor: theme.palette.primary.main,
        display: 'block',
        textAlign: 'center',
        color: theme.palette.background.paper,
        fontSize: 10,
        fontWeight: 500,
    },
    '& .fc .fc-popover': {
        border: 'none',
        borderRadius: '8px',
        boxShadow: theme.shadows[1],
        zIndex: 111,
    },
    '& .fc .fc-popover-header': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontSize: 12,
        fontWeight: 600,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
    },
    '& .fc .fc-daygrid-event-harness': {
        padding: 2,
        cursor: 'pointer',
    },
}));

const ShiftsCalendar: FC = () => {
    const calendarRef = useRef<FullCalendar>(null);
    const [initialEvents, setInitialEvents] = useState<EventInputDepartmentId[]>();
    const [allEvents, setAllEvents] = useState<EventInputDepartmentId[]>();
    const [date, setDate] = useState(new Date());
    const { i18n } = useTranslation();
    const isMobile = screen.width <= 768;
    const isTablet = screen.width <= 1024;
    const [departments, setDepartments] = useState<Department[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<{ label: string; id: number; } | null>(null);
    const { seasonId } = useSeason();
    const [viewEventState, setViewEventState] = useState<{
        isOpened: boolean;
        event?: EventImpl;
    }>({
        isOpened: false,
    });
    const [addEventState, setAddEventState] = useState<{
        isOpened: boolean;
        events?: EventInput[];
        startedOn?: Date;
    }>({
        isOpened: false,
        startedOn: new Date(),
    });

    const findSelectedLanguage = () => {
        switch (i18n.language) {
            case 'en':
                return enLocale;
            case 'it':
                return itLocale;
            case 'de':
                return deLocale;
            default:
                return enLocale;
        }
    };

    const nextMonth = () => {
        const calendarApi = calendarRef.current?.getApi();
        if (!calendarApi) {
            return;
        }
        calendarApi.next();
        setDate(calendarApi.getDate());
    };

    const prevMonth = () => {
        const calendarApi = calendarRef.current?.getApi();
        if (!calendarApi) {
            return;
        }
        calendarApi.prev();
        setDate(calendarApi.getDate());
    };

    const highlightToday = () => {
        const calendarApi = calendarRef.current?.getApi();
        if (!calendarApi) {
            return;
        }
        calendarApi.today();
        setDate(calendarApi.getDate());
    };

    function renderEventContent(eventContent: EventContentArg) {
        const maxLength = 20;
        const eventTitle = eventContent.event.title;
        const truncatedTitle = eventTitle.length > maxLength ? `${eventTitle.substring(0, maxLength)}...` : eventTitle;

        return isMobile ?
            (
                <Tooltip title={eventContent.event.title}>
                    <FlexBox style={{ fontSize: '10px', marginLeft: '-8px' }}>{truncatedTitle}</FlexBox>
                </Tooltip>

            )
            :
            (
                <Tooltip title={eventContent.event.title}>
                    <FlexBox height={30} alignItems={'center'}>
                        {truncatedTitle}
                    </FlexBox>
                </Tooltip>
            );
    }

    const fetchData = async () => {
        const role = checkTokenRole();
        const { data } = role === 'DEPCHIEF' ? await calendarEntriesShiftTemplateApi.findAllWhereIsBoss(seasonId)
            : await calendarEntriesShiftTemplateApi.findAll(seasonId);
        const events = data.map((entry: CalendarEntryShiftTemplateEntity) => {
            const extendedEntry = entry as ExtendedCalendarEntryShiftTemplateEntity;
            return convertToEvent(extendedEntry);
        })

        setAllEvents(events);
        filterEventsByDepartment(selectedDepartment, events);
    };

    const filterEventsByDepartment = (department: { label: string; id: number; } | null, events: EventInput[] | undefined) => {
        if (!department || !events) {
            setInitialEvents(events);
        } else {
            const filteredEvents = events.filter((entry: EventInput) =>
                entry.departmentId === department.id
            );
            setInitialEvents(filteredEvents);
        }
    }

    // The department is passed directly here to avoid the synchronisation problems of the setSelectedDepartment
    const handleDepartmentChange = (department: { label: string; id: number; } | null) => {
        setSelectedDepartment(department);
    };

    useEffect(() => {
        filterEventsByDepartment(selectedDepartment, allEvents);
    }, [selectedDepartment])

    function convertToEvent(entry: CalendarEntryShiftTemplateEntity) {
        return {
            id: String(entry.id),
            start: new Date(entry.startedOn),
            // fullcalendar's end date is exclusive
            end: entry.endedOn,
            allDay: true,
            title: entry.shiftTemplate?.name,
            templateId: entry.templateId,
            departmentId: entry.shiftTemplate?.departmentId
        };
    }

    useEffect(() => {
        fetchData();
        fetchAllDepartments();
    }, []);

    const fetchAllDepartments = async () => {
        const role = checkTokenRole();
        const departments = role === 'DEPCHIEF' ? (await departmentsApi.findWhereIsBoss(seasonId)).data : (await departmentsApi.findAll()).data;
        setDepartments(departments);
        if (departments.length > 0)
            handleDepartmentChange({
                label: departments[0].name,
                id: departments[0].id
            })

    }

    const openDetailView = (clickArg: EventClickArg) => {
        const event = clickArg.event;
        setViewEventState({
            isOpened: true,
            event
        });
    };

    const addEventFromDate = (dateArg: DateClickArg) => {
        const eventsOnDate = allEvents?.filter(event => {
            // Event.start has the completed date including time, it is substringed to take only the part obtained from dateArg
            return event.start?.toString().substring(0, 15) === dateArg.date.toDateString();
        });
        const calendarApi = dateArg.view.calendar;
        const events = calendarApi.getEvents();

        const eventExists = events.some((event) => {
            return event.start?.toDateString() === dateArg.date.toDateString();
        });

        if (!eventExists) {

            setAddEventState({
                isOpened: true,
                events: eventsOnDate,
                startedOn: dateArg.date
            });
        }
    };



    return (
        <Box pt={2} pb={4}>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <Autocomplete
                        id="department-selector"
                        options={departments?.map((department) => ({
                            label: department.name,
                            id: department.id
                        })) || []}
                        value={selectedDepartment}
                        onChange={(event, value) => {
                            setSelectedDepartment(value ? { label: value.label, id: value.id } : null);
                            handleDepartmentChange(value ? { label: value.label, id: value.id } : null);
                        }}
                        renderInput={(params) =>
                            <AppTextField
                                {...params}
                                placeholder={t('department.departmentSelector.select')}
                                label={t('department.itemName')}
                            />
                        }
                        fullWidth
                        isOptionEqualToValue={(option, value) => option.id === value?.id} // It serves to prevent alerts/errors mui in the console
                    />
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <HeaderToolbar
                            date={date}
                            onDatePrev={() => {
                                prevMonth();
                            }}
                            onDateNext={() => {
                                nextMonth();
                            }}
                            onToday={() => {
                                highlightToday();
                            }}
                        />
                        <CalendarWrapper>
                            <FullCalendar
                                timeZone='UTC'
                                eventDisplay='block'
                                initialDate={date}
                                eventClick={openDetailView}
                                dateClick={addEventFromDate}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                ref={calendarRef}
                                height={600}
                                rerenderDelay={10}
                                events={initialEvents}
                                headerToolbar={false}
                                firstDay={1}
                                eventContent={renderEventContent}
                                locale={findSelectedLanguage()}
                                dayHeaderFormat={{ weekday: isMobile ? 'narrow' : isTablet ? 'short' : 'long' }}
                                dayMaxEventRows={3}
                                moreLinkClick="popover"
                            />
                        </CalendarWrapper>
                    </Card>
                    {viewEventState.isOpened && (
                        <ViewEventModalShift
                            open={viewEventState.isOpened}
                            onClose={() => {
                                setViewEventState({ isOpened: false });
                            }}
                            fetchCalendarData={fetchData}
                            event={viewEventState.event}
                            departmentId={allEvents?.find(event => event.id === viewEventState.event?.id)?.departmentId}
                        />
                    )}

                    {addEventState.isOpened && (
                        <AddEventModalShift
                            originalSelectedDepartment={selectedDepartment}
                            open={addEventState.isOpened}
                            onClose={() => {
                                setAddEventState({ isOpened: false });
                            }}
                            fetchCalendarData={fetchData}
                            date={addEventState.startedOn}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ShiftsCalendar;
