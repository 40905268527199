import { FC } from 'react';
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers';  // Importing type for DatePicker
import { styled } from '@mui/material';


const StyledDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
    width: '100%',

    '& .MuiOutlinedInput-input': {
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px',
        borderColor: theme.palette.action.disabled,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.action.hover,
    },
    '& .MuiInputLabel-root': {
        fontWeight: 500,
        color: theme.palette.text.disabled,
    },
    '& .MuiInputLabel-root.Mui-focused': { fontWeight: 600 },
    '& .MuiSvgIcon-root': { color: theme.palette.text.disabled },
}));

const CalendarInput: FC<DatePickerProps<Date>> = (props) => {
    return <StyledDesktopDatePicker {...(props as any)
    
    } />;
};

export default CalendarInput;