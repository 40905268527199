import { Accordion, AccordionSummary, Box, Button, Grid, IconButton, useTheme } from "@mui/material";
import { shiftApi, workPositionsApi } from "api";
import { ShiftDetailEntity, WorkPositionEntity } from "api/generated";
import { FC, useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { H3 } from "components/Typography";
import { ignoreUTC } from "utils/utils";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { it } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import DeleteIcon from "icons/DeleteIcon";
import AddIcon from '@mui/icons-material/Add';
import { useSeason } from "contexts/SeasonContext";


interface ModalProps {
    data: ShiftDetailEntity[];
    fetchDetailsData: () => void;
    openAddShiftDetail: () => void;
    turnStart: Date;
    turnEnd: Date;
    values: {
        name: string;
        startTime: Date;
        endTime: Date;
    };
    isEdited: boolean;
    setIsEdited: (param: boolean) => void;
    departmentId: number
}

const timeOption: any = {
    timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit'
}


const PositionAccordion: FC<ModalProps> = ({ data, turnStart, turnEnd, fetchDetailsData, openAddShiftDetail, values, isEdited, setIsEdited, departmentId }) => {

    const [workPositions, setWorkPositions] = useState<WorkPositionEntity[]>([]);
    const theme = useTheme();
    const { t } = useTranslation();
    const [editableMap, setEditableMap] = useState(new Map<number, { editable: boolean, startTime: Date, endTime: Date }>());
    const { seasonId } = useSeason();

    //  const [isEdited, setIsEdited] = useState(false);

    const fetchData = async () => {
        const seenWorkPositionsIds: number[] = [];
        data.forEach((shiftDetail) => {

            editableMap.set(shiftDetail.id, {
                editable: false,
                startTime: shiftDetail.startTime ? ignoreUTC(new Date(shiftDetail.startTime), true) : turnStart,
                endTime: shiftDetail.endTime ? ignoreUTC(new Date(shiftDetail.endTime), true) : turnEnd
            });

            // push new workPosition id if it has not been seen altready
            if (!seenWorkPositionsIds.includes(shiftDetail.workPositionId))
                seenWorkPositionsIds.push(shiftDetail.workPositionId);
        })
        const workPositions = (await workPositionsApi.findAll(departmentId, seasonId)).data.filter(x => seenWorkPositionsIds.includes(x.id));
        setWorkPositions(workPositions);
    }

    const saveShiftDetails = () => {
        editableMap.forEach(
            (item, id) => {
                // copy map by value
                //    const newMap = new Map(editableMap);
                const currentValues = editableMap.get(id);
                if (!currentValues)
                    return

                // update times only if times are different from turn start or a startTime is already defined
                const shouldUpdateStartTime = +currentValues.startTime !== +turnStart || item.startTime;
                const shouldUpdateEndTime = +currentValues.endTime !== +turnEnd || item.endTime;

                shiftApi.updateShiftDetails(id, {

                    startTime: shouldUpdateStartTime ? ignoreUTC(currentValues?.startTime).toISOString() : undefined,
                    endTime: shouldUpdateEndTime ? ignoreUTC(currentValues?.endTime).toISOString() : undefined,
                    departmentId,
                    seasonId
                }).then(fetchDetailsData);

                // edit and set current editable value to false
                currentValues.editable = false;

            }
        )
    }

    const remove = async (id: number) => {
        await shiftApi.removeDetail(id, departmentId, seasonId).then(fetchDetailsData);

    }

    useEffect(() => {
        fetchData();
        if (data.length === 0)
            setIsEdited(false);
    }, [data]);

    return (

        <Box maxHeight={'50vh'} overflow={'auto'} paddingTop={2}>

            <Grid container paddingBottom={2} paddingTop={1} spacing={1} direction={'row-reverse'} display={'flex'} columns={14}>
                <Grid item xs={3}>
                    <Button fullWidth endIcon={<AddIcon />}
                        disabled={!values.name || !values.startTime || !values.endTime}
                        variant="contained" onClick={() => { openAddShiftDetail() }}>
                        {t('shift.addResource')}
                    </Button>
                </Grid>
                <Grid xs={3} item>
                    {
                        data.length > 0 && (
                            <Button disabled={!isEdited} variant="contained" fullWidth
                                onClick={() => {
                                    saveShiftDetails();
                                    setIsEdited(false);
                                }}
                            >
                                {t('shift.saveSpecialTimes')}
                            </Button>

                        )
                    }

                </Grid>

            </Grid>

            {
                data.length > 0 && (
                    workPositions.map((workPosition) => {

                        const items = data.filter(item => item.workPositionId === workPosition.id);



                        return (
                            <Accordion key={workPosition.id}>

                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingBottom: 2 }}>
                                    <Grid container alignContent={'center'} justifyContent={'center'} padding={"10px"} >
                                        <Grid item xs={9} sm={11} zeroMinWidth >
                                            <H3
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-word',
                                                    overflow: 'hidden',
                                                    minWidth: '0',
                                                    maxWidth: '100%', // Ensures the text does not exceed the parent width
                                                    display: 'flex',
                                                    padding: '5px 10px',
                                                }}>
                                                {workPosition.name}
                                            </H3>
                                        </Grid>
                                        <Grid item xs={3} sm={1}>
                                            <H3
                                                style={{
                                                    textAlign: 'center',
                                                    display: 'inline-block',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                    borderRadius: '150px',
                                                    color: 'white',
                                                    backgroundColor: theme.palette.primary.main,
                                                    width: '70%'
                                                }}>
                                                {items.length}
                                            </H3>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                {items.map((item, index) => {
                                    const editable = editableMap.get(item.id)?.editable
                                    return (
                                        <Grid item container spacing={1} xs={12} key={item.id} alignItems={'center'} minHeight={90} paddingBottom={2}
                                            sx={{ borderTop: 1, borderColor: "lightgray", paddingLeft: '2vw' }} >

                                            <Grid xs={4} item>
                                                {workPosition.name + ' ' + '#' + (index + 1)}
                                            </Grid>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                                                <Grid item xs={2}>
                                                    {
                                                        editable ?
                                                            (
                                                                <TimePicker
                                                                    value={
                                                                        editableMap.get(item.id)?.startTime
                                                                    }
                                                                    onChange={(value) => {
                                                                        const currentValue = editableMap.get(item.id);
                                                                        if (value && !isNaN(+value) && currentValue) {
                                                                            currentValue.startTime.setSeconds(0, 0);
                                                                            currentValue.startTime = value;
                                                                        }
                                                                    }}

                                                                />

                                                            ) :
                                                            (
                                                                <>
                                                                    {ignoreUTC(editableMap.get(item.id)?.startTime ?? new Date()).toLocaleTimeString(undefined, timeOption)}
                                                                </>
                                                            )
                                                    }
                                                    { }
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {
                                                        editableMap.get(item.id)?.editable ?
                                                            (
                                                                <TimePicker
                                                                    value={
                                                                        editableMap.get(item.id)?.endTime
                                                                    }
                                                                    onChange={(value) => {
                                                                        const currentValue = editableMap.get(item.id);
                                                                        if (value && !isNaN(+value) && currentValue ) {
                                                                            currentValue.endTime.setSeconds(0, 0);
                                                                            currentValue.endTime = value;
                                                                        }
                                                                    }}
                                                                />

                                                            ) :
                                                            (
                                                                <>
                                                                    {ignoreUTC(editableMap.get(item.id)?.endTime ?? new Date()).toLocaleTimeString(undefined, timeOption)}
                                                                </>
                                                            )}
                                                </Grid>

                                            </LocalizationProvider>
                                            <Grid item xs={2}>
                                                {
                                                    !editable &&
                                                    (

                                                        <Button onClick={() => {
                                                            // copy map by value
                                                            const newMap = new Map(editableMap);
                                                            const currentValues = editableMap.get(item.id);
                                                            if (!currentValues)
                                                                return
                                                            // edit and set current editable value to true
                                                            currentValues.editable = true;
                                                            newMap.set(item.id, {
                                                                ...currentValues
                                                            });
                                                            // set new state to update components
                                                            setEditableMap(newMap);
                                                            setIsEdited(true);
                                                        }}>
                                                            {t('common.forms.button.edit')}
                                                        </Button>
                                                    )


                                                }

                                            </Grid>
                                            <Grid item xs={2}>
                                                <IconButton
                                                    onClick={async () => {
                                                        if (editableMap.get(item.id)?.editable)
                                                            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                                            await remove(item.id);
                                                    }}
                                                    disabled={!editable}
                                                >
                                                    <DeleteIcon
                                                        sx={{ alignSelf: 'center', display: 'flex' }}
                                                        color={!editable ? "disabled" : "error"}

                                                        style={{
                                                            cursor: 'pointer',

                                                        }}
                                                    />
                                                </IconButton>

                                            </Grid>
                                        </Grid>

                                    )
                                })}



                            </Accordion>
                        )

                    }))
            }


        </Box >
    )

}

export default PositionAccordion;