import { Alert, Button, FormControlLabel, Grid, Switch, TextField, Typography, styled, useMediaQuery } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import { H2, Tiny } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { DocumentTypeEntity } from 'api/generated';
import AppSelectField from 'components/input-fields/MultipleChoiceField';
import { KeyboardArrowDown } from '@mui/icons-material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { documentTypesApi, idDocumentsApi } from 'api';
import FileUploadInput from 'components/input-fields/FileUploadInput';

// component props interface
interface UpdateZipProps {
    open: boolean;
    onClose: () => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 900,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const UploadZipModal: FC<UpdateZipProps> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:499px)');
    const [documentTypes, setDocumentTypes] = useState<DocumentTypeEntity[]>([]);
    const [selectedType, setSelectedType] = useState<DocumentTypeEntity>();
    const [isVisible, setIsVisible] = useState(true);
    const [prefix, setPrefix] = useState('')
    const [selectedFile, setSelectedFile] = useState<File>();
    const [notAssignedFiles, setNotAssignedFiles] = useState<string[]>([]);
    const [createdFiles, setCreatedFiles] = useState(0);
    const [error, setError] = useState('');
    const [sentFile, setSentFile] = useState(false);

    useEffect(() => {
        documentTypesApi.findAll().then(({ data }) => {
            setDocumentTypes(data);
        })
    }, [])

    const resetAll = () => {
        setSelectedType(undefined);
        setIsVisible(true);
        setSelectedFile(undefined);
        setSentFile(false);
        setCreatedFiles(0)
        setNotAssignedFiles([])
        setPrefix('')
    }

    const sendFile = (async () => {
        if (selectedType && selectedFile) {
            try {
                const formData = new FormData();
                formData.append('file', selectedFile);
                let uploadedZip;
                if (prefix === ""){
                    uploadedZip = (await idDocumentsApi.createFileIfTaxCode(selectedType.name, isVisible.toString(), { data: formData })).data;
                } else {
                    uploadedZip = (await idDocumentsApi.createFileIfTaxCodePrefix(selectedType.name, isVisible.toString(), prefix, { data: formData })).data;
                }
                const notCreated = uploadedZip.badFiles;
                const created = uploadedZip.count;
                setCreatedFiles(created)
                if (notCreated.length > 0) {
                    setNotAssignedFiles(notCreated);
                }
            } catch (e: any) {
                setError(e.message);
            }
            setSentFile(true);
        }
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsVisible(event.target.checked);
    };

    function handleTypeChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setSelectedType(documentTypes.find((type) => {
            return type.name === event.target.value;
        }))

    }

    const resultModal = () => {
        if (error !== '') {
            return (
                <>
                    <AppModal open={sentFile} handleClose={() => {
                        resetAll();
                        onClose();
                    }}>
                        <H2 mb={2}>
                            {t('alerts.error')}
                        </H2>
                        <Alert variant="outlined" severity="error">
                            {t('uploadZip.fileFormatError')}
                        </Alert>
                        <Button
                            sx={{ marginTop: 2 }}
                            fullWidth
                            size='small'
                            type='button'
                            variant='contained'
                            onClick={() => {
                                resetAll();
                                onClose();
                            }}
                        >
                            {t('confirm')}
                        </Button>
                    </AppModal>
                </>
            )
        } else {
            return (
                <>
                    <AppModal open={sentFile} handleClose={() => {
                        resetAll();
                        onClose();
                    }}>
                        <H2 mb={2}>
                            {t('uploadZip.completed')}
                        </H2>
                        {createdFiles > 0 ?
                            <Alert variant="outlined" severity="success">
                                {t('uploadZip.success', { item: createdFiles })}
                            </Alert>
                            : <></>
                        }

                        {notAssignedFiles.length > 0 ? <>
                            <Alert style={{ marginTop: 15 }} variant="outlined" severity="error">
                                {t('uploadZip.notAssignedMessage')}
                                <ul style={{ marginTop: 1 }}>
                                    {notAssignedFiles.map((file) => {
                                        return (<li> {file} </li>);
                                    })}
                                </ul>
                            </Alert>
                        </> : <></>}

                        <Button
                            fullWidth
                            sx={{ marginTop: 2 }}
                            size='small'
                            type='button'
                            variant='contained'
                            onClick={() => {
                                resetAll();
                                onClose();
                            }}
                        >
                            {t('confirm')}
                        </Button>
                    </AppModal>
                </>
            )
        }

    }

    return (
        <StyledAppModal open={open} handleClose={() => {
            resetAll();
            onClose();
        }}>
            <H2 mb={2}>
                {t('common.tables.button.UploadZip')}
            </H2>

            <p>
                {t('uploadZip.description')}
            </p>

            <Tiny>
                {t('uploadZip.filesFormat')}
            </Tiny>

            <form>
                <Grid container mt={2}
                    spacing={3}
                    textAlign={isMobile ? "left" : "center"}
                    alignItems="center"
                    justifyItems={"center"}
                    justifyContent={"center"}
                >
                    <Grid item xs={4}>
                        <AppSelectField
                            InputLabelProps={{ shrink: true }}
                            select
                            variant='outlined'
                            placeholder={t('employees.id.documentTypes.default')}
                            SelectProps={{
                                native: true,
                                IconComponent: KeyboardArrowDown,
                            }}
                            value={selectedType?.name}
                            onChange={(e) => {
                                handleTypeChange(e)
                            }}
                        >
                            <option>{t('employees.id.documentTypes.default')}</option>
                            {documentTypes.map((documentType) => (
                                <option key={documentType.id} value={documentType.name}>
                                    {documentType.name}
                                </option>
                            ))}

                        </AppSelectField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={t('uploadZip.prefix')}
                            value={prefix}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPrefix(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            sx={{ minWidth: '0px' }}
                            label={<Typography textOverflow={'ellipsis'} overflow={'hidden'}>{t('employees.id.documentTypes.isVisible')}</Typography>}
                            labelPlacement='start'
                            control={
                                <Switch name='isVisibleForUser' checked={isVisible} onChange={handleChange} />
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FileUploadInput
                            handleOnChange={(e) => {
                                if (e.target.files) {
                                    setSelectedFile(Array.from(e.target.files)[0]);
                                }
                            }}
                            resetOnChange={false}
                            disabled={false}
                            multiple={false}
                        />
                    </Grid>
                </Grid>


                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth variant='outlined' onClick={() => {
                        resetAll();
                        onClose();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth
                        disabled={!selectedFile || !selectedType}
                        size='small'
                        type='button'
                        variant='contained'
                        onClick={sendFile}
                    >
                        {t('common.forms.button.upload')}
                    </Button>
                </FlexBox>
            </form>
            {resultModal()}

        </StyledAppModal>
    );
};

export default UploadZipModal;