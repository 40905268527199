import LoadingScreen from 'components/LoadingScreen';
import jwtDecode from 'jwt-decode';
import { createContext, type ReactNode, useEffect, useReducer } from 'react';
import { accountApi, authApi } from '../api';
import { AccountEntity } from '../api/generated';
import { NavigateFunction } from 'react-router-dom';
import { checkTokenRole } from 'utils/checkToken';

export interface DecodedToken {
  email: string;
  sub: string;
  role?: string;
  employeeId?: string;
  tenant?: string;
}


interface InitialAuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AccountEntity | null;
  refreshToken: string | null;
}

// props type
interface AuthProviderProps {
  children: ReactNode;
}

// --------------------------------------------------------

const initialState: InitialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  refreshToken: null,
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) return false;
  const decodedToken = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const startSession = (accessToken: string, refreshToken: string) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};
const closeSession = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

const reducer = (state: InitialAuthState, action: any): InitialAuthState => {
  switch (action.type) {
    case 'INIT': {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
        refreshToken: action.payload.refreshToken,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        refreshToken: action.payload.refreshToken,
      };
    }
    case 'LOGOUT': {
      return { ...state, user: null, isAuthenticated: false };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  logout: async () => {
    await Promise.resolve();
  },
  login: async (email: string, password: string, navigate: NavigateFunction) => {
    await Promise.resolve();
  },
  register: async (username: string, email: string, password: string, navigate: NavigateFunction) => {
    await Promise.resolve();
  },
  loginWithJwtTokens: async (accessToken: string, refreshToken: string, navigate: NavigateFunction) => {
    await Promise.resolve();
  },
  deleteAccount: async () => {
    await Promise.resolve();
  },
});

export const JWTAuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email: string, password: string, navigate: NavigateFunction) => {
    const { data } = await authApi.login({
      email,
      password,
    });
    await loginWithJwtTokens(data.access_token, data.refresh_token, navigate);
  };

  const register = async (
    username: string,
    email: string,
    password: string,
    navigate: NavigateFunction

  ) => {
    const { data } = await authApi.register({
      username,
      email,
      password,
    });
    await loginWithJwtTokens(data.access_token, data.refresh_token, navigate);
  };

  const logout = async () => {
    await authApi.logout();
    closeSession();
    dispatch({ type: 'LOGOUT' });
  };

  const loginWithJwtTokens = async (
    accessToken: string,
    refreshToken: string,
    navigate: NavigateFunction,
  ) => {
    startSession(accessToken, refreshToken);
    const decodedToken = jwtDecode<DecodedToken>(accessToken);

    if (decodedToken.tenant !== undefined) {
      const { data: userProfile } = await accountApi.find();
      dispatch({
        type: 'LOGIN',
        payload: { user: userProfile, refreshToken },
      });
      try {
        const role = checkTokenRole();
        switch (role) {
          case 'DEPCHIEF': {
            navigate('/dashboard/shift-assignments');
            break;
          }
          case 'USER': {
            navigate('/employee/defaultUserPage');
            break;
          }
          default: {
            navigate('/dashboard');
            break;
          }
        }
      }
      catch {
        navigate('/')
      }
    } else {
      navigate('/select-tenant');
      const { data: userProfile } = await accountApi.find();
      dispatch({
        type: 'LOGIN',
        payload: { user: userProfile, refreshToken },
      });
    }
  };

  const deleteAccount = async () => {
    await accountApi.remove();
    closeSession();
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (accessToken && refreshToken && isValidToken(refreshToken)) {
          const { data: userProfile } = await accountApi.find();
          dispatch({
            type: 'INIT',
            payload: {
              user: userProfile,
              isAuthenticated: true,
              refreshToken,
            },
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              user: null,
              isAuthenticated: false,
              refreshToken,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INIT',
          payload: { user: null, isAuthenticated: false },
        });
      }
    })();
  }, []);

  // show loading until not initialized
  if (!state.isInitialized) <LoadingScreen />;
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        register,
        logout,
        loginWithJwtTokens,
        deleteAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
