import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Button, Card, Theme, styled, useMediaQuery } from '@mui/material';
import { departmentsApi, shiftTemplateApi } from 'api';
import { CustomDialog } from 'components/CustomDialog';
import FlexBox from 'components/flexbox/FlexBox';
import { useState, type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ShiftTemplateEntity } from 'api/generated';
import ShiftTemplateDataTable from 'page-sections/data-table/ShiftTemplateDataTable';
import AddShiftTemplateModal from 'page-sections/data-table/dataTableV2/AddShiftTemplateModal';
import { Department } from 'pages/dashboards/dashboard-main-page';
import SearchInput from 'components/input-fields/SearchInput';
import { searchByName } from 'utils/utils';
import AppTextField from 'components/input-fields/AppTextField';
import FlexBetween from 'components/flexbox/FlexBetween';
import { checkTokenRole } from 'utils/checkToken';
import { useSeason } from 'contexts/SeasonContext';

// styled components
const ButtonWrapper = styled(FlexBox)(({ theme }) => ({
    [theme.breakpoints.down(500)]: {
        marginTop: 10,
        width: '100%',
        flexDirection: 'column-reverse',
        '& > .MuiBox-root': {
            width: '100%',
            margin: '10px 0',
            alignItems: 'center',
            flexDirection: 'column',
        },
        '& .MuiButton-root': { minWidth: '100%' },
    },
}));

const ShiftsTable: FC = () => {
    const [tableData, setTableData] = useState([{}]);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [shiftTemplateData, setShiftTemplateData] = useState<ShiftTemplateEntity | null>(null);
    const { t } = useTranslation();
    const [departments, setDepartments] = useState<Department[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<{ label: string; id: number; } | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const [filteredItem, setFilteredItem] = useState(tableData);
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200));
    const isDepChief = checkTokenRole() === 'DEPCHIEF';
    const { seasonId } = useSeason();

    const fetchTableData = async () => {
        (isDepChief ? shiftTemplateApi.findAllWhereIsBoss(seasonId) : shiftTemplateApi.findAll())
            .then(({ data }) => {
                setTableData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    useEffect(() => {
        fetchTableData();
        fetchAllDepartments();
    }, []);

    const fetchAllDepartments = () => {
        (isDepChief ? departmentsApi.findWhereIsBoss(seasonId) : departmentsApi.findAll()).then(({ data: departments }) => {
            setDepartments(departments);
        });
    }


    const handleRowSelect = (rowArr: []) => {
        setSelectedRows(rowArr);
    };

    const ids = selectedRows.map((item: any) => item.original.id);
    const handleDelete = async () => {
        handleOpenDeleteConfirmation();
    };

    const handleConfirmDelete = async () => {
        const deleteActions = ids.map(async (id) => shiftTemplateApi.remove(id));
        await Promise.all(deleteActions);
        fetchTableData();
        // Close confirmation dialog
        handleCloseDeleteConfirmation();
    };

    const handleCloseModal = () => { setOpen(false) }

    const handleAddShiftTemplate = (data?: ShiftTemplateEntity | null) => {
        setShiftTemplateData(data ?? null);
        setOpen(true);
    };

    const applyFilters = () => {
        let filteredData = tableData;
        if (selectedDepartment) {
            filteredData = filteredData.filter((item: any) => item.departmentId === selectedDepartment.id);
        }
        if (searchValue) {
            filteredData = searchByName(filteredData, searchValue);
        }
        setFilteredItem(filteredData);
    };

    useEffect(() => {
        applyFilters();
    }, [searchValue, selectedDepartment, tableData]);

    return (
        <>
            <AddShiftTemplateModal
                key={new Date().getTime()} // Needed to update modal if it opens with the same data 
                open={open}
                onClose={handleCloseModal}
                edit={!!shiftTemplateData}
                data={shiftTemplateData}
                setData={setShiftTemplateData}
                fetchData={fetchTableData}
                departmentsData={departments}
            />
            <CustomDialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                onConfirm={handleConfirmDelete}
                title={t('dialog.title', {
                    item: t('shiftTemplate.itemName'),
                })}
                content={t('dialog.content')}
                icon={<DeleteForeverIcon />}
                confirmText={t('dialog.confirm')}
                confirmColor='error'
                cancelColor='primary'
            />

            <FlexBetween flexWrap='wrap' mt={2}>
                <FlexBox width={downMd ? '100%' : 'auto'} flexBasis="600px" gap={2} alignItems={'center'} >
                    <SearchInput
                        bordered={false}
                        placeholder={t('shiftTemplate.searchTemplate')}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                    />
                    <Autocomplete
                        id="department-selector"
                        options={departments?.map((department) => ({
                            label: department.name,
                            id: department.id
                        })) || []}
                        value={selectedDepartment}
                        onChange={(event, value) => {
                            setSelectedDepartment(value ? { label: value.label, id: value.id } : null);
                        }}
                        renderInput={(params) =>
                            <AppTextField
                                {...params}
                                placeholder={t('department.departmentSelector.select')}
                                label={t('department.itemName')}
                            />
                        }
                        fullWidth
                        isOptionEqualToValue={(option, value) => option.id === value?.id} // It serves to prevent alerts/errors mui in the console
                    />
                </FlexBox>
                <ButtonWrapper alignItems='center'>
                    <FlexBox gap={2} justifyContent={'end'} width={'100%'}>
                        {selectedRows.length > 0 && (
                            <Button
                                size='small'
                                color='error'
                                variant='contained'
                                onClick={handleDelete}
                                sx={{ color: 'common.white' }}
                            >
                                {t('common.tables.select.deleteSelected')}
                            </Button>
                        )}
                        <Button
                            endIcon={<AddIcon />}
                            variant='contained'
                            color='primary'
                            onClick={() => { handleAddShiftTemplate() }}
                            sx={{ fontSize: 'small' }}
                        >
                            {t('common.tables.button.addItem', {
                                item: t('shiftTemplate.itemName'),
                            })}
                        </Button>
                    </FlexBox>
                </ButtonWrapper>
            </FlexBetween>
            <Card sx={{ marginTop: 3 }}>
                <ShiftTemplateDataTable
                    data={filteredItem}
                    handleRowSelect={handleRowSelect}
                    editShiftTemplateCallback={handleAddShiftTemplate}
                />
            </Card>
        </>
    );
}

export default ShiftsTable;