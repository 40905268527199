import { useState, FC, useEffect } from 'react';
import { Box, Tooltip } from '@mui/material';
import { employeeNotesApi } from 'api';
import { EmployeeEntity, NoteEntity } from 'api/generated';
import FlexBox from 'components/flexbox/FlexBox';
import { Tiny } from 'components/Typography';
import DeleteIcon from 'icons/DeleteIcon';
import EditIcon from '@mui/icons-material/Edit';
import { CustomDialog } from 'components/CustomDialog';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useTranslation } from 'react-i18next';
import { useHidden } from 'contexts/HiddenContext';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import { decryptData, encryptData } from 'utils/encryptionDecryptionAgreements';
import EncryptionKeyIcon from '@mui/icons-material/Https';
import NoEncryptionKeyIcon from '@mui/icons-material/NoEncryptionGmailerrorred';

interface NoteEntityWithAuthor extends NoteEntity {
  author?: Pick<EmployeeEntity, 'firstName' | 'lastName'>;
}

interface NotesBubbleProps {
  noteInstance?: NoteEntityWithAuthor;
  fetchNotesDataCallback: () => void;
  editNoteCallback: (note: NoteEntity) => void;
  setEncryptedCallback: (id: number, text: string, text_encrypted: string) => void;
  disableHover?: boolean;
}

const NotesBubble: FC<NotesBubbleProps> = ({ noteInstance, fetchNotesDataCallback, editNoteCallback, setEncryptedCallback, disableHover = false }) => {
  const { t } = useTranslation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [formattedDateTime, setFormattedDateTime] = useState('');
  const [formattedDateTimeCreatedAt, setFormattedDateTimeCreatedAt] = useState('');
  const hidden = useHidden();
  const { encryptionKey } = useEncryption();
  const [isNoteOk, setIsNoteOk] = useState(false);
  const [decryptedText, setDecryptedText] = useState('');

  const handleDeleteDialogOpen = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirmed = () => {
    deleteNote(noteInstance?.id);
    handleDeleteDialogClose();
  };

  const deleteNote = (id?: number) => {
    if (id) {
      employeeNotesApi
        .remove(id)
        .then(() => {
          fetchNotesDataCallback();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const buttonOnClick = (isEncryption: boolean) => {
    if (noteInstance)
      if (isEncryption) {
        const encryptedText = encryptData(noteInstance.text, encryptionKey);
        setEncryptedCallback(noteInstance.id, '', encryptedText);
      } else {
        const decryptedText = decryptData(noteInstance.text_encrypted, encryptionKey);
        setEncryptedCallback(noteInstance.id, decryptedText, '');
      }
  }

  const parseDate = (date: string) => {
    const dateObject = new Date(date);
    return (dateObject.toLocaleString());
  }
  useEffect(() => {
    const createdAtString = noteInstance?.createdAt;
    if (createdAtString) {
      setFormattedDateTimeCreatedAt(parseDate(createdAtString));
    }

    if (noteInstance?.text !== "") {
      setIsNoteOk(true); // Note in cleartext
    }

    if (noteInstance?.text_encrypted) {
      const text = decryptData(noteInstance.text_encrypted, encryptionKey);
      setDecryptedText(text);
      if (text === '') {
        setIsNoteOk(false); // Failed decryption
      } else {
        setIsNoteOk(true); // Successful decryption
      }
    }
  }, [noteInstance]);

  useEffect(() => {
    const editedAtString = noteInstance?.editedAt;
    if (editedAtString) {
      setFormattedDateTime(parseDate(editedAtString));
    }
  }, [noteInstance?.editedAt]);


  return (
    <Box
      position="relative"
    >
      <FlexBox gap={1.5} justifyContent='end' position="relative"
        onMouseEnter={() => { !disableHover && setIsHovered(true) }}
        onMouseLeave={() => { setIsHovered(false) }}
      >
        <Box
          sx={{
            padding: 2,
            borderRadius: '8px',
            backgroundColor: isHovered && !disableHover ? 'primary.dark' : 'primary.main',
          }}
        >
          <Tiny display='block' color='white' fontWeight={500} lineHeight={1.7} whiteSpace={'pre-line'}
            style={!isNoteOk || (hidden.hidden && noteInstance?.text_encrypted !== "") ? { filter: 'blur(3px)', opacity: 1 } : {}}>
            {noteInstance?.text ? noteInstance.text : decryptedText !== "" ? decryptedText : "・・・"}
          </Tiny>
        </Box>
        {isHovered && !disableHover && (
          <Box
            position="absolute"
            top={-14}
            right={0}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            p={1}
            borderRadius="8px"
            bgcolor="rgba(92, 84, 112, 1)"
            style={{ cursor: 'pointer' }}
          >
            <DeleteIcon
              style={{ fontSize: '16px', color: 'white' }}
              onClick={handleDeleteDialogOpen}
            />
            {!noteInstance || (!hidden.hidden || decryptedText === "" || !noteInstance.text_encrypted) && isNoteOk && (
              <EditIcon
                style={{ fontSize: '16px', color: 'white', marginLeft: '8px' }}
                onClick={() => {
                  if (noteInstance) {
                    editNoteCallback(noteInstance);
                  }
                }}
              />
            )}
            {encryptionKey && encryptionKey !== "" && (
              noteInstance && noteInstance.text !== "" ? (
                <EncryptionKeyIcon
                  style={{ fontSize: '16px', color: 'white', marginLeft: '8px' }}
                  onClick={() => { buttonOnClick(true) }}
                />
              ) : (
                <NoEncryptionKeyIcon
                  style={{ fontSize: '16px', color: 'white', marginLeft: '8px' }}
                  onClick={() => { buttonOnClick(false) }}
                />
              )
            )}
          </Box>
        )}
      </FlexBox>
      {noteInstance?.createdAt !== noteInstance?.editedAt ? (
        <Tooltip
          title={`${t('notes.createdAt')} ${formattedDateTimeCreatedAt}`}
          arrow placement='bottom-end'
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
        >
          <div>
            <Tiny mt={1} fontSize={10} fontWeight={500} textAlign='right'>
              {noteInstance?.createdAt !== noteInstance?.editedAt ? `${t('notes.editMessage')} ` : ""}
              {noteInstance?.author ? ( noteInstance?.author.firstName + ' ' + noteInstance?.author?.lastName ) : t('common.deletedUser')} 
              - {formattedDateTime}
            </Tiny>
          </div>
        </Tooltip>
      ) :
        <div>
          <Tiny mt={1} fontSize={10} fontWeight={500} textAlign='right'>
            {noteInstance?.author ? noteInstance?.author?.firstName + ' ' + noteInstance?.author?.lastName : t('common.deletedUser')} - {formattedDateTime}
          </Tiny>
        </div>
      }

      <CustomDialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirmed}
        title={t('notes.dialog.title')}
        content={t('notes.dialog.content')}
        confirmColor='error'
        cancelColor='primary'
        confirmText={t('notes.dialog.confirmText')}
        icon={<CheckCircleOutlinedIcon />}
      />
    </Box>
  );
};

export default NotesBubble;
