import { Button, Grid, styled } from '@mui/material';
import AppTextField from '../../../components/input-fields/AppTextField';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { H2 } from '../../../components/Typography';
import { CalendarEntryEntity } from '../../../api/generated';
import { calendarEntriesApi, employeesApi } from '../../../api';
import AppModal from '../../../components/AppModal';
import { checkTokenRole } from 'utils/checkToken';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  id: number;
  employeeId: number;
  seasonId: number;
  color: string;
  fetchCalendarData: () => void;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 500,
  minWidth: 200,

  [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

type EventData = Omit<CalendarEntryEntity, 'createdById' | 'updatedById'> & {
  createdBy?: string;
  updatedBy?: string;
};

const ViewEventModal: FC<ModalProps> = ({ open, onClose, id, color, fetchCalendarData, employeeId, seasonId }) => {
  const { t } = useTranslation();
  const [event, setEvent] = useState<EventData>();
  const isChief = checkTokenRole() === 'DEPCHIEF';


  function toDateString(isoDateTime: string): string {
    return isoDateTime.slice(0, 10);
  }

  function toDateTimeString(isoDateTime: string): string {
    return isoDateTime.slice(0, 16);
  }

  async function handleDelete() {
    await calendarEntriesApi.remove(String(id), seasonId, employeeId);
    fetchCalendarData();
    onClose();
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await calendarEntriesApi.findOne(String(id), String(employeeId), seasonId);
      const { createdById, updatedById, ...rest } = data;

      async function fetchName(employeeId: number) {
        const { data: employee } = await employeesApi.findOneWithoutSeaoson(
          String(employeeId),
        );
        return `${employee.firstName} ${employee.lastName}`;
      }

      if (!isChief) {
        const [createdBy, updatedBy] = await Promise.all([
          createdById ? fetchName(createdById) : undefined,
          updatedById ? fetchName(updatedById) : undefined,
        ]);
        setEvent({
          ...rest,
          createdBy,
          updatedBy,
        });
      }
      else {
        setEvent(rest);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  return event ? (
    <StyledAppModal open={open} handleClose={onClose}>
      <H2 mb={2} sx={{ p: 3, backgroundColor: color }}>
        {t(`employees.calendar.types.${event.type.toLowerCase()}`)}
      </H2>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AppTextField
            disabled={true}
            label={t('employees.calendar.entry.startedOn')}
            value={toDateString(event.startedOn)}
            fullWidth
            type='date'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={6}>
          <AppTextField
            disabled={true}
            value={toDateString(event.endedOn)}
            label={t('employees.calendar.entry.endedOn')}
            fullWidth
            type='date'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12}></Grid>
        {!isChief && (<Grid item xs={6}>
          <AppTextField
            disabled={true}
            value={event.createdBy ?? t('common.deletedUser')}
            label={t('employees.calendar.entry.createdBy')}
            fullWidth
            variant='outlined'
          />
        </Grid>)}

        <Grid item xs={6}>
          <AppTextField
            disabled={true}
            value={toDateTimeString(event.createdAt)}
            label={t('employees.calendar.entry.createdAt')}
            fullWidth
            type='datetime-local'
            variant='outlined'
          />
        </Grid>
        {!isChief && (<Grid item xs={6}>
          <AppTextField
            disabled={true}
            value={event.updatedBy ?? t('common.deletedUser')}
            label={t('employees.calendar.entry.updatedBy')}
            fullWidth
            variant='outlined'
          />
        </Grid>)}
        <Grid item xs={6}>
          <AppTextField
            disabled={true}
            value={toDateTimeString(event.updatedAt)}
            label={t('employees.calendar.entry.updatedAt')}
            fullWidth
            type='datetime-local'
            variant='outlined'
          />
        </Grid>
      </Grid>
      <Button
        size='small'
        color='error'
        variant='contained'
        onClick={handleDelete}
        sx={{ color: 'common.white' }}
      >
        {t('employees.calendar.entry.deleteEntry')}
      </Button>
    </StyledAppModal>
  ) : (
    <></>
  );
};

export default ViewEventModal;
