import { NoteEntity, EmployeesToDepartmentsEntity, ContractEntity } from 'api/generated'
import * as ExcelJS from 'exceljs';
import { TFunction } from 'i18next';
import { translateContractType } from './utils';
import { decryptData } from './encryptionDecryptionAgreements';
import { fetchCurrentPaymentData } from 'pages/employees/reportUtils';
import { addDays, differenceInDays, format, isSameDay } from 'date-fns';
import { calendarEntriesApi } from 'api';
function formatDate(value: string): string {
    if (isDate(value)) {
        const date = new Date(value);
        return date.toLocaleDateString('it-IT');
    }
    return value;
}

function isDate(value: string): boolean {
    // Use a regex to check whether the string is in the format 'yyyy-MM-ddTHH:mm:ss.sssZ'
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return typeof value === 'string' && dateRegex.test(value);
}

export async function createReportForUser(employee: any, fileName: string, t: any) {
    // This array contains all the undesidered keys that we don't want include in the report
    const undesideredKeys = ['id', 'contract', 'bankingInformation', 'employeesToDepartments', 'employeeId', 'filePath', 'roomId', 'statusId',
        'buildingId', 'maxTenants', 'floor', 'accessMode', 'bathrooms', 'hasKitchen', 'notes', 'room', 'agreements'];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t('report.sheetTitle'));
    // Set header design for the report
    reportHeaderForUserDesign(worksheet, t);
    worksheet.getColumn('A').width = 22;
    const columnB = worksheet.getColumn('B');
    worksheet.getColumn('E').width = 22;
    const columnF = worksheet.getColumn('F');
    worksheet.getColumn('I').width = 22;
    const columnJ = worksheet.getColumn('J');
    worksheet.getColumn('M').width = 22;
    const columnN = worksheet.getColumn('N');
    worksheet.getColumn('Q').width = 30;
    const columnR = worksheet.getColumn('R');
    // Set width for dynamic columns
    columnB.width = 0;
    columnF.width = 0;
    columnJ.width = 0;
    columnN.width = 0;
    columnR.width = 10;
    // Transforms data into arrays, if employee.relation is empty assign the empty array to the keys array
    const keysEmployee = Object.keys(employee);
    const keysBanking = employee.bankingInformation ? Object.keys(employee.bankingInformation) : [];
    const keysContract = employee.employeesToSeasons[0].contract ? Object.keys(employee.employeesToSeasons[0].contract) : [];
    const keysRoom = employee.employeesToSeasons[0].room ? Object.keys(employee.employeesToSeasons[0].room) : [];
    const { notes } = employee;
    const agreements = employee.employeesToSeasons[0].contract?.agreements ?? [];
    const maxKeysLength = Math.max(keysEmployee.length, keysBanking.length, keysContract.length, keysRoom.length, agreements.length);
    // Create filtered keys for employee, banking information and contract
    const keyEmployeeFiltered = keysEmployee.filter((keyEmployee) => !undesideredKeys.includes(keyEmployee));
    const keyBankingFiltered = keysBanking.filter((keyBanking) => !undesideredKeys.includes(keyBanking));
    const keyContractFiltered = keysContract.filter((keyContract) => !undesideredKeys.includes(keyContract));
    const keyRoomFiltered = keysRoom.filter((keysRoom) => !undesideredKeys.includes(keysRoom));
    // Print to report all the filtered keys + values 
    for (let i = 0; i < maxKeysLength; i++) {
        const agreement = agreements[i];
        const createdAt = agreement ? formatDate(agreement.createdAt) : '';
        const startedOn = agreement ? formatDate(agreement.startedOn) : '';
        const endedOn = agreement ? formatDate(agreement.endedOn) : '';

        // Function to extract the value from the composed key
        const extractValue = (compositeKey?: { name: string } | null) => {
            return compositeKey?.name ?? compositeKey;
        };

        const roomValue = extractValue(employee.employeesToSeasons[0].room?.[keyRoomFiltered[i]]);

        const agreementValidDate = startedOn || endedOn ? `${t('employees.agreements.excel.valid')} ${startedOn} ${t('employees.agreements.excel.to')} ${endedOn}` : '';

        worksheet.addRow([
            keyEmployeeFiltered[i],
            formatDate(employee[keyEmployeeFiltered[i]]),
            '',
            '',
            keyBankingFiltered[i],
            formatDate(employee.bankingInformation?.[keyBankingFiltered[i]]),
            '',
            '',
            keyContractFiltered[i],
            formatDate(employee.employeesToSeasons.contract?.[keyContractFiltered[i]]),
            '',
            '',
            keyRoomFiltered[i],
            roomValue,
            '',
            '',
            agreementValidDate,
            createdAt
        ]);

        worksheet.addRow([]);
    }

    // Calculating column size. Employee must be define since the button to genereate the report only exist if the employee exist 
    const employeeLength = getMaxLength(Object.values(employee));
    const bankingLength = employee.bankingInformation ? getMaxLength(Object.values(employee.bankingInformation)) : 0;
    const contractLength = employee.employeesToSeasons[0].contract ? getMaxLength(Object.values(employee.employeesToSeasons[0].contract)) : 0;
    const roomLength = employee.employeesToSeasons[0].room ? getMaxLength(Object.values(employee.employeesToSeasons[0].room)) : 0;


    columnB.width = Math.min(Math.max(columnB.width, employeeLength + 6), 50);
    columnF.width = Math.min(Math.max(columnF.width, bankingLength + 6), 50);
    columnJ.width = Math.min(Math.max(columnJ.width, contractLength + 6), 50);
    columnN.width = Math.min(Math.max(columnN.width, roomLength + 6), 50);

    // Set report Design
    reportForUserDesign(worksheet);
    await addLogoImageToReport(worksheet, workbook);

    const worksheetNotes = workbook.addWorksheet(t('report.sheetTitleNotes'));
    worksheetNotes.getColumn('A').width = 22;
    worksheetNotes.getColumn('D').width = 50;

    worksheetNotes.addRow([t('report.createdAtNotes'), '', '', t('report.textNotes')]);
    worksheetNotes.addRow([]);
    notes.forEach((note: NoteEntity) => {
        worksheetNotes.addRow([formatDate(note.createdAt), '', '', note.text]);
        worksheetNotes.addRow([]);
    });
    notesDesign(worksheetNotes);

    downloadWorkbook(workbook, fileName);
}

// Function to calculate the max length of values array
function getMaxLength(obj: any): number {
    if (obj === null || obj === undefined) {
        return 0;
    }
    if (typeof obj !== "object") {
        return obj.toString().length;
    }
    const values = Object.values(obj);
    const normalKeysLength = Math.max(...values.map((value) => getMaxLength(value)));
    const subKeysLength = Math.max(...Object.keys(obj).map((key) => getMaxLength(obj[key])));

    return Math.max(normalKeysLength, subKeysLength);
}

// Function to create the header design for the report
function reportHeaderForUserDesign(worksheet: ExcelJS.Worksheet, t: any) {
    worksheet.addRow([t('report.title')])
    worksheet.mergeCells('A1:R2');
    worksheet.addRow([]);
    worksheet.addRow([t('report.columnTitle.general'), '', '', '', t('report.columnTitle.banking'), '', '', '', t('report.columnTitle.contract'),
        '', '', '', t('housing.collectionName'), '', '', '', t('employees.agreements.title'),
    t('employees.agreements.dataTable.createdOn')]);
    worksheet.addRow([]);
}

// Function to create the design for the report
function reportForUserDesign(worksheet: ExcelJS.Worksheet) {
    const boldCells = ['A4', 'E4', 'I4', 'M4', 'Q4'];
    worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DCE6F1' },
    }
    // Cell Formatting
    worksheet.eachRow((row: any) => {
        row.alignment = { wrapText: true }
        row.eachCell((cell: any, colNumber: any) => {
            if (cell.value !== '') {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'DCE6F1' },
                }
            };
            cell.font = Object.assign({}, { name: 'Montserrat', size: 9, bold: boldCells.includes(cell._address) });
            // Align values on the right hand side
            if (colNumber === 2 || colNumber === 6 || colNumber === 10 || colNumber === 14) {
                cell.alignment = { horizontal: 'right' };
            }
        });
    });
    // Set title format
    worksheet.getCell('A1').font = { name: 'Montserrat', size: 12, bold: true };
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
}

async function addLogoImageToReport(worksheet: ExcelJS.Worksheet, workbook: ExcelJS.Workbook) {
    const imageSrc = "/static/logo/logo_onestaff_new.png";
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    const logo = workbook.addImage({
        buffer,
        extension: 'png',
    });
    worksheet.addImage(logo, {
        tl: { col: 0.3, row: 0.1 },  // Coordinates
        ext: { width: 60, height: 40 }, // Image size
    });
}

function downloadWorkbook(workbook: ExcelJS.Workbook, fileName: string) {
    // Generate the Excel file as a blob
    workbook.xlsx.writeBuffer().then(function (buffer: ArrayBuffer) {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element
        const downloadLink = document.createElement('a');

        // Use the blob to set the href for the link
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;

        // Set the download attribute for the link to specify the filename
        downloadLink.download = fileName;

        // Append the link to the document, trigger a click, and then remove it
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    });
}



export async function createReportForAllUsers(users: any, fileName: string, t: any, encryptionKey?: string) {
    // Defines column keys
    const keys = [
        'firstName', 'lastName', 'taxcode', 'email', 'phone', 'dateOfBirth', 'countryOfBirth', 'provinceOfBirth', 'townshipOfBirth',
        'contract.typeName', 'contract.startedOn', 'contract.endedOn', 'contract.net',
        'bankingInformation.iban', 'bankingInformation.bicSwift', 'role',
        'contract.isFoodIncluded', 'isHoused',
        'buildingName', 'roomName',
        'segments', 'departments', 'workPositions'
    ];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t('report.sheetTitleMultiEmployees'));
    reportHeaderForAllUsersDesign(worksheet, keys, t);


    users.sort((a: any, b: any) => {
        if (a.firstName < b.firstName) return -1;
        if (a.firstName > b.firstName) return 1;
        if (a.lastName < b.lastName) return -1;
        if (a.lastName > b.lastName) return 1;
        return 0;
    }).forEach((userData: any) => {
        const departmentLenght = userData.employeesToDepartments.length;


        if (userData.employeesToSeasons[0]) {
            if (userData.employeesToSeasons[0].room) {
                // using roomName and buildingName to differentiate names keys
                userData.roomName = userData.employeesToSeasons[0].room.name;
                userData.buildingName = userData.employeesToSeasons[0].room.building.name;
            }
            if (userData.employeesToSeasons[0].contract) {
                userData.contract = userData.employeesToSeasons[0].contract;
                userData.contract.typeName = translateContractType(userData.contract.legalContractType)
                // if agreements is defined
                if (userData.contract.agreements[0]) {
                    if (userData.contract.agreements[0].net_daily_encrypted !== null && encryptionKey) {
                        const net = decryptData(userData.contract.agreements[0].net_daily_encrypted, encryptionKey);
                        if (net !== '') {
                            userData.contract.net = Number(net) * 30;
                        }
                    }
                    else
                        userData.contract.net = userData.employeesToSeasons[0].contract.agreements[0]?.net_daily * 30;

                }
            }
        }

        userData.departments = '';
        userData.segments = '';
        userData.workPositions = '';
        userData.employeesToDepartments.forEach((employeeToDepartment: EmployeesToDepartmentsEntity, index: number) => {
            if (employeeToDepartment.department?.segment?.name && !(userData.segments as string).includes(employeeToDepartment.department?.segment?.name)) {
                userData.segments += employeeToDepartment.department?.segment?.name;
                if (departmentLenght - index > 1)
                    userData.segments += ', ';
            }

            userData.departments += employeeToDepartment.department?.name;
            if (departmentLenght - index > 1)
                userData.departments += ', ';

            if (departmentLenght > 1)
                userData.workPositions += employeeToDepartment.department?.name + ': ' + employeeToDepartment.workPosition?.name;
            else
                userData.workPositions += employeeToDepartment.workPosition?.name;

            if (departmentLenght - index > 1)
                userData.workPositions += ', ';

        })

        userData.isHoused = Boolean(userData.room);
        worksheet.addRow(keys.map((keyItem) => {
            return formatDate(keyItem.split('.').reduce((acc, key) => acc?.[key], userData));
        }));

        reportForAllUsersDesign(worksheet.lastRow, keys, worksheet);
    });

    await addLogoImageToReport(worksheet, workbook);
    downloadWorkbook(workbook, fileName);
}

function translateKeys(t: TFunction<"translation", undefined>, key?: string) {
    switch (key) {
        case 'firstName':
            return t('employees.generalInformation.firstName');
        case 'lastName':
            return t('employees.generalInformation.lastName');
        case 'taxcode':
            return t('employees.generalInformation.taxcode');
        case 'email':
            return t('auth.email');
        case 'phone':
            return t('employees.generalInformation.phone');
        case 'dateOfBirth':
            return t('employees.generalInformation.dateOfBirth.label');
        case 'countryOfBirth':
            return t('employees.generalInformation.country');
        case 'provinceOfBirth':
            return t('employees.generalInformation.province');
        case 'townshipOfBirth':
            return t('employees.generalInformation.township');
        case 'typeName':
            return t('employees.contract.contractType.label');
        case 'startedOn':
            return t('employees.calendar.entry.startedOn');
        case 'endedOn':
            return t('employees.calendar.entry.endedOn');
        case 'net':
            return t('employees.agreements.field.monthly.net');
        case 'iban':
            return t('employees.bank.iban');
        case 'bicSwift':
            return t('employees.bank.bicswift');
        case 'role':
            return t('employees.generalInformation.role');
        case 'isFoodIncluded':
            return t('employees.contract.foodIncluded');
        case 'isHoused':
            return t('excel.isHoused');
        case 'roomName':
            return t('room.housing.title');
        case 'buildingName':
            return t('housing.itemName');
        case 'segments':
            return t('department.segments');
        case 'departments':
            return t('departments.collectionName');
        case 'workPositions':
            return t('departments.workPosition.label');
        case 'due':
            return t('report.paymentsTable.toBePaid');
        case 'bankName':
            return t('employees.bank.bankName');
        default:
            return key;


    }
}

function toUTC(date: Date) {
    const dateStr = format(date, 'yyyy-MM-dd');
    return new Date(`${dateStr}T00:00:00.000Z`);
}
function reportHeaderForAllUsersDesign(worksheet: ExcelJS.Worksheet, keys: string[], t: any, isWireReport?: boolean) {
    worksheet.addRow([t('report.title')])
    isWireReport ? worksheet.mergeCells('A1:F2') : worksheet.mergeCells('A1:V2');
    worksheet.addRow(keys.map(key => translateKeys(t, key.split('.').pop()))).font = { name: 'Montserrat', size: 9, bold: true };
    worksheet.getCell('A1').font = { name: 'Montserrat', size: 12, bold: true };
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DCE6F1' },
    }
}

// Indent and format rows
function reportForAllUsersDesign(row: any, keys: string[], worksheet: ExcelJS.Worksheet) {
    for (let i = 1; i <= keys.length; i++) {
        const columnName = String.fromCharCode(64 + i);
        worksheet.getColumn(columnName).width = 22;
        const cell = worksheet.getCell(`${columnName}${row.number}`);
        if (row._number % 2 === 0) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'DCE6F1' },
            };
        }
        cell.font = {
            name: 'Montserrat',
            size: 9,
        };
        cell.alignment = {
            horizontal: 'left',
            vertical: 'bottom',
            wrapText: false,
        }
    }
}

function notesDesign(worksheet: ExcelJS.Worksheet) {
    const columnNotesD = worksheet.getColumn('D');
    columnNotesD.alignment = { wrapText: true };
    const boldCells = ['A1', 'D1'];
    boldCells.forEach(cellRef => {
        const cell = worksheet.getCell(cellRef);
        cell.font = { bold: true };
    });

    // Cell Formatting
    worksheet.eachRow((row: any) => {
        row.alignment = { wrapText: true }
        row.eachCell((cell: any) => {
            if (cell.value !== '') {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'DCE6F1' },
                }
            };
        })
    });
}

export async function createExportCalendar(employees: Array<{ employeeId: number, name: string }>, startDate: Date, endDate: Date, fileName: string, t: TFunction<"translation", undefined>, seasonId: number) {

    const workbook = new ExcelJS.Workbook();
    // create worksheet with frozen first column
    const worksheet = workbook.addWorksheet(t('employees.calendar.events'), {
        views: [
            { state: 'frozen', xSplit: 1, ySplit: 1 }
        ]
    });

    const duration = differenceInDays(endDate, startDate);

    calendarHeader(worksheet, startDate, endDate);


    // order by name
    for (const employee of employees.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    })) {
        const { employeeId, name } = employee;
        const values = [name];
        const entries = (await calendarEntriesApi.findAll(employeeId.toString(), seasonId)).data.filter((entry) => {
            return (+addDays(new Date(entry.startedOn), -1) <= +endDate && +addDays(new Date(entry.endedOn), 1) >= +startDate)
        });

        for (let i = 0; i <= duration; i++) {
            const value = entries.find((entry) => {
                const currentDate = addDays(new Date(startDate), i);
                const start = toUTC(new Date(entry.startedOn));
                const end = toUTC(new Date(entry.endedOn));
                return (+currentDate >= +start && +currentDate <= +end) || isSameDay(currentDate, start) || isSameDay(currentDate, end);
            })?.type ?? 'WORK';

            const translated = translateCalendarEntries(value, t);

            values.push(translated);
        }
        worksheet.addRow(values);

    }


    // conditional styling to color events cells
    if (worksheet.lastColumn && worksheet.lastRow) {

        worksheet.addConditionalFormatting({
            ref: 'A1:' + worksheet.lastColumn.letter + worksheet.lastRow?.number,
            rules: [
                {
                    priority: 1,
                    type: 'containsText',
                    operator: 'containsText',
                    text: translateCalendarEntries("HALF_DAY", t),
                    style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: '00EAFF' } } },
                },
                {
                    priority: 1,
                    type: 'containsText',
                    operator: 'containsText',
                    text: translateCalendarEntries("DAY_OFF", t),
                    style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: 'FFCDC2' } } },
                },
                {
                    priority: 1,
                    type: 'containsText',
                    operator: 'containsText',
                    text: translateCalendarEntries("VACATION", t),
                    style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: 'BDF0DA' } } },
                },
                {
                    priority: 1,
                    type: 'containsText',
                    operator: 'containsText',
                    text: translateCalendarEntries("ILL", t),
                    style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: 'FFC0D3' } } },
                },
                {
                    priority: 1,
                    type: 'containsText',
                    operator: 'containsText',
                    text: translateCalendarEntries("UNPAID_LEAVE", t),
                    style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: 'D2BCEB' } } },
                },
            ]
        })
    }
    worksheet.eachRow(row => {
        row.height = 25;
    })

    downloadWorkbook(workbook, fileName);
}

const translateCalendarEntries = (type: string, t: TFunction<"translation", undefined>) => {

    switch (type) {
        case "WORK":
            return t('employees.calendar.types.work');
        case "DAY_OFF":
            return t('employees.calendar.types.day_off');
        case "VACATION":
            return t('employees.calendar.types.vacation');
        case "HALF_DAY":
            return t('employees.calendar.types.half_day');
        case "ILL":
            return t('employees.calendar.types.ill');
        case "UNPAID_LEAVE":
            return t('employees.calendar.types.unpaid_leave');
        default:
            return type;

    }

}

function calendarHeader(worksheet: ExcelJS.Worksheet, startDate: Date, endDate: Date) {
    const dates: any = [''];

    // name column styling
    const firstColumn = worksheet.getColumn('A');
    firstColumn.width = 30;
    firstColumn.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DCE6F1' },
    };

    firstColumn.font = {
        bold: true
    }


    const columnLetters: number[] = [2];

    for (let currentDate = new Date(startDate); +currentDate < +endDate || isSameDay(currentDate, endDate); currentDate = addDays(currentDate, 1)) {
        dates.push(new Date(Date.UTC((currentDate.getFullYear()), (currentDate.getMonth()), currentDate.getDate())));
        let columnName = '';

        columnLetters.forEach((letter) => {
            columnName = String.fromCharCode(64 + letter) + columnName;
        })
        worksheet.getColumn(columnName).width = 22;

        if (columnLetters.every(value => value === 26)) {
            for (let i = 0; i < columnLetters.length; i++)
                columnLetters[i] = 1;
            columnLetters.push(1);
        }
        else {
            columnLetters[columnLetters.findIndex(value => value < 26)]++;
        }
    }


    worksheet.addRow(dates);

    // header styling

    const row = worksheet.getRow(1);

    row.font = { name: 'Montserrat', size: 9, bold: true };

    row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DCE6F1' },
    };
}

export async function createWireTransfersReportForAllUsers(users: any, fileName: string, t: any, encryptionKey: string, date: Date) {
    // defines column keys
    const keys = [
        'firstName', 'lastName', 'bankName', 'iban', "bicSwift",
        'due'
    ];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t('report.sheetTitleMultiEmployees'));
    reportHeaderForAllUsersDesign(worksheet, keys, t, true);

    // for instead of forEach needed to avoid sync issues
    // order by last name
    for (const userData of users.sort((a: any, b: any) => {
        if (a.firstName < b.firstName) return -1;
        if (a.firstName > b.firstName) return 1;
        if (a.lastName < b.lastName) return -1;
        if (a.lastName > b.lastName) return 1;
        return 0;
    })) {

        if (userData) {

            let totalEarned = 0;

            let totalPaid = 0;

            const setTotalEarned = (x: number) => { totalEarned = x };

            const setTotalPaid = (x: number) => { totalPaid = x };

            const contract = userData.employeesToSeasons?.at(0)?.contract as ContractEntity;

            if (!contract)
                continue;
            const seasonId = contract.legalContractType === 'UNLIMITED' ? null : userData.employeesToSeasons?.at(0).seasonId as number;

            await fetchCurrentPaymentData(userData.id, seasonId, contract.id, encryptionKey, setTotalEarned, setTotalPaid, date);

            const net = (totalEarned - totalPaid);
            userData.bankName = userData.bankingInformation?.bankName;
            userData.iban = userData.bankingInformation?.iban;
            userData.bicSwift = userData.bankingInformation?.bicSwift;
            userData.due = net;

            worksheet.addRow(keys.map((keyItem) => {
                return formatDate(keyItem.split('.').reduce((acc, key) => acc?.[key], userData));
            }));
            reportForAllUsersDesign(worksheet.lastRow, keys, worksheet);
        }
    };

    wireTransfersDesign(worksheet, date, t);

    await addLogoImageToReport(worksheet, workbook);
    downloadWorkbook(workbook, fileName);

}

// Indent and format net rows
function wireTransfersDesign(worksheet: ExcelJS.Worksheet, date: Date, t: TFunction<"translation", undefined>) {
    worksheet.addRow([t('report.selectDate') + ': ' + date.toLocaleDateString('it-IT')]);
    const columnE = worksheet.getColumn('E');
    const lastRow = columnE.values.length;

    const dateCell = 'A' + lastRow;
    const lastCell = 'F' + lastRow;

    worksheet.mergeCells(dateCell + ':' + lastCell);
    worksheet.getCell(dateCell).font = { name: 'Montserrat', size: 8, bold: true };
    worksheet.getCell(dateCell).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DCE6F1' },
    }


}   